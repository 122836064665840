<template>
  <headervue></headervue>
  <first></first>
  <second></second>
  <yangiliklar></yangiliklar>
  <contanct></contanct>
  <users></users>
</template>

<script>
import headervue from "./header.vue";
import first from "./first.vue";
import second from "./second.vue";
import contanct from "./contanct.vue";
import Yangiliklar from "./yangiliklar.vue";
import users from "./users.vue";
export default {
  components: {
    headervue,
    first,
    second,
    Yangiliklar,
    contanct,
    users,
  },
};
</script>

<style>
</style>
