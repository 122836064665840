<template>
    <div class="container">
      <p class="mainh1">{{ $t("mainTitle") }}</p>
      <div class="test-list">
        <div class="test-item">
          <p class="test-name">{{ $t("anesthesiaIntubation") }}</p>
          <p class="test-range">1.196.000 - 2.392.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{ $t("anesthesiaEpidural") }}</p>
          <p class="test-range">1.196.000 - 2.392.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{ $t("anesthesiaSpinal") }}</p>
          <p class="test-range">1.318.000 - 2.636.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{ $t("anesthesiaMuscle") }}</p>
          <p class="test-range">1.491.000 - 2.982.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{ $t("anesthesiaRegional") }}</p>
          <p class="test-range">1.491.000 - 2.982.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{ $t("anesthesiaIntravenous") }}</p>
          <p class="test-range">1.196.000 - 2.392.000</p>
        </div>
  
        
      </div>
      
      <div class="service-page">
        <p class="title">{{ $t("servicesTitle") }}</p>
        <div class="content-description">
          <p>
            {{ $t("descriptionOfServices") }}
          </p>
        </div>
        <a href="../../assets/pfd/list.pdf" class="pdf-button" download>{{ $t("downloadPdfLabel") }}</a>
    </div>
      </div>
  </template>
  
  <script setup>
  </script>
  
  <style lang="scss" scoped>
  .mainh1 {
    font-size: 42px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
    color: #007bff;
    text-transform: uppercase;
  }
  
  .test-list {
    width: 100%;
    display: grid; 
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    animation: fadeIn 1s ease-out;
  }
  
  .test-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #9E9E9E;
    margin: 5px 0;
  }
  
  .test-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }
  
  .test-name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.4;
    text-transform: capitalize;
    transition: color 0.3s ease;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .content-description p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    text-align: center;
    margin: 0 20px;
  }
  
  .test-range {
    font-size: 16px;
    color: #666;
    font-weight: 400;
    transition: color 0.3s ease;
  }
  
  .test-range:hover {
    color: #06829b;
  }
  
  
.pdf-button {
    background-color: #06829b;
    color: white;
    padding: 12px 20px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    width: 300px;  
    margin: 30px auto;  
    display: block;  
    transition: background-color 0.3s ease;
  }
  
  .pdf-button:hover {
    background-color: #0056b3;
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .test-list {
      grid-template-columns: 1fr; 
    }
  }
  </style>
  