<template>
  <div class="container">
    <div class="service-page">
      <p class="title">{{ $t("header1010") }}</p>
      <div class="content-description">
        <p>
          {{ $t("serviceDescription1") }}
        </p>
      </div>
      
    </div>
    <div class="card">
        <iframe
            src="/pdf/service1.pdf"
            frameborder="0"
            width="100%"
            height="700px"
          ></iframe>
      </div>
  </div>
</template>
  
  <script>
  export default {
    name: "ServicePage",
    data() {
      return {
      };
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>

  
  /* Title */
  .title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  /* Service description text */
  .content-description p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    text-align: center;
    margin: 0 20px;
  }

  .card{
    background: rgb(240, 240, 240);
    padding: 40px;
    margin: 30px 0;
  }
  
  /* Button styling */
  .download-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }
  
  .btn-download {
    background-color: #06829b;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .btn-download:active {
    background-color: #003f8e;
  }
  
  .btn-text {
    font-weight: 500;
    letter-spacing: 1px;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .title {
      font-size: 28px;
    }
    
    .content-description p {
      font-size: 14px;
    }
  
    .btn-download {
      padding: 12px 24px;
      font-size: 16px;
    }
    .service-page{
        height: 550px;
      }
  }
  </style>
  