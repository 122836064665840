<template>
  <div class="container">
    <p class="header">{{ $t('header') }}</p>
    
    <section v-for="(section, index) in sections" :key="index" class="section">
      <div v-if="section.type === 'text'" class="section-content">
        <p v-for="(paragraph, pIndex) in section.content" :key="pIndex">{{ paragraph }}</p>
      </div>

      <div v-if="section.type === 'timeline'" class="timeline">
        <div v-for="(item, itemIndex) in section.items" :key="itemIndex" class="timeline-item">
          <div class="timeline-date">{{ item.year }}</div>
          <div class="timeline-content">
            <p>{{ item.title }}</p>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();  // Correct way to access translations in <script setup>

// Define sections using computed to ensure translation happens reactively
const sections = computed(() => [
  {
    type: 'text',
    content: [t('textt')] // translation dynamically here
  },
  {
    type: 'timeline',
    items: [
      { year: 1954, title: t('titleee'), description: t('descriptionnn') },
      { year: 1957, title: t('title1'), description: t('description1') },
      { year: 1970, title: t('title2'), description: t('description2') },
      { year: 2017, title: t('title3'), description: t('description3') },
      { year: 1993, title: t('title4'), description: t('description4') }
    ]
  },
  {
    type: 'text',
    content: [
      t('leaders'),
      t('text1970'),
      t('tyext1983'),
      t('current')
    ]
  }
]);
</script>

<style scoped>


.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin: 40px 0; 
  color: #2c3e50;
  text-transform: uppercase;
  animation: fadeInUp 1s ease-in-out;
}

/* Section styles */
.section {
  margin-bottom: 50px;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-content p {
  font-size: 18px;
  line-height: 1.6;
  color: #34495e;
  margin-bottom: 15px;
}

.timeline {
  padding-left: 20px;
  margin-top: 20px;
}

.timeline-item {
  margin-bottom: 30px;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.timeline-item:hover {
  transform: translateX(10px);
}

.timeline-date {
  font-size: 20px;
  font-weight: 600;
  color: #06829b;
  margin-bottom: 10px;
}

.timeline-content p {
  font-size: 22px;
  font-weight: bold;
}

.timeline-content p {
  font-size: 16px;
  color: #7f8c8d;
}

/* Hover effect on timeline items */
.timeline-item:hover .timeline-content p {
  color: #06829b;
}

.timeline-content {
  padding-left: 10px;
  border-left: 3px solid #06829b;
}


/* Animation effects */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(50px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Smooth scroll effect */
html {
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  .header {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .section {
    padding: 15px;
  }

  .section-content p {
    font-size: 16px;
  }

  .timeline-item {
    margin-bottom: 20px;
  }

  .timeline-date {
    font-size: 18px;
  }

  .timeline-content p {
    font-size: 14px;
  }

  .timeline-content {
    padding-left: 8px;
  }

  .timeline-item:hover {
    transform: translateX(5px);
  }
}

/* Responsive styles for tablets and larger phones */
@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .section {
    padding: 18px;
  }

  .section-content p {
    font-size: 17px;
  }

  .timeline-date {
    font-size: 19px;
  }

  .timeline-content p {
    font-size: 15px;
  }
}
</style>
