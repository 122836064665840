<template>
  <div class="container">
    <div class="oncology-services">
      <p class="mainh1">{{$t('surgeryIII')}}</p>
      <header class="header text-center mb-5">
        <p class="mainh1">{{ $t('oncologyHeader710') }}</p>
        <p class="lead">{{ $t('oncologySubheader711') }}</p>
      </header>

      <section class="oncology-services-section">
        <p class="mainh2">{{ $t('ourServices712') }}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3 service-title">{{ $t('diagnosisAndTreatment713') }}</p>
              <p class="service-description">
                {{ $t('diagnosisAndTreatmentDescription714') }}
              </p>
              <img :src="img1" alt="Tashxis" class="service-image fade-in">
            </div>
          </div>
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3 service-title">{{ $t('surgicalOperations715') }}</p>
              <p class="service-description">
                {{ $t('surgicalOperationsDescription716') }}
              </p>
              <img :src="img2" alt="Jarrohlik Amaliyoti" class="service-image fade-in">
            </div>
          </div>
        </div>
      </section>

      <section class="onco-ophthalmology-section mt-5">
        <p class="mainh2">{{ $t('oncoOphthalmology717') }}</p>
        <p class="lead">{{ $t('oncoOphthalmologySubheader718') }}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3 service-title">{{ $t('eyeTumors719') }}</p>
              <p class="service-description">
                {{ $t('eyeTumorsDescription720') }}
              </p>
              <img :src="img3" alt="Ko‘z O‘smalari" class="service-image fade-in">
            </div>
          </div>
          <div class="col-md-6">
            <div class="service-card">
              <p class="mainh3 service-title">{{ $t('cosmeticSurgery721') }}</p>
              <p class="service-description">
                {{ $t('cosmeticSurgeryDescription722') }}
              </p>
              <img :src="img4" alt="Kosmetik Jarrohlik" class="service-image fade-in">
            </div>
          </div>
        </div>
      </section>

      <!-- Surgery Procedures Details -->
      <section class="surgery-details mt-5">
        <p class="mainh2">{{ $t('surgicalProcedures723') }}</p>
        <ul>
          <li>{{ $t('surgicalProcedures724') }}</li>
          <li>{{ $t('surgicalProcedures725') }}</li>
          <li>{{ $t('surgicalProcedures726') }}</li>
          <li>{{ $t('surgicalProcedures727') }}</li>
          <li>{{ $t('surgicalProcedures728') }}</li>
        </ul>
      </section>

      <!-- Facilities Section -->
      <section class="facilities mt-5">
        <p class="mainh2">{{ $t('modernEquipment729') }}</p>
        <div class="row">
          <div class="col-md-4">
            <div class="facility-card">
              <p class="mainh3">{{ $t('microsurgicalEquipment730') }}</p>
              <p>{{ $t('microsurgicalEquipmentDescription731') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="facility-card">
              <p class="mainh3">{{ $t('radiotherapy732') }}</p>
              <p>{{ $t('radiotherapyDescription733') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="facility-card">
              <p class="mainh3">{{ $t('rehabilitation734') }}</p>
              <p>{{ $t('rehabilitationDescription735') }}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
  import img1 from '@/assets/jarrohlik3.1.jpg'
  import img2 from '@/assets/jarrohlik3.2.jpg'
  import img3 from '@/assets/jarrohlik3.3.jpg'
  import img4 from '@/assets/jarrohlik4.3.jpg'
export default {
    data(){
      return{
        img1,
        img2,
        img3,
        img4
      }
    }
  }
</script>

<style scoped>
.oncology-services {
  padding: 30px 0;
}

.mainh1 {
  text-align: center;
  font-size: 2.7rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.mainh2 {
  font-size: 2rem;
  color: #003366;
  margin-bottom: 20px;
}

.mainh3 {
  font-size: 1.5rem;
  color: #333;
}

ul {
  padding-left: 20px;
  list-style-type: square;
}

.service-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 20px 0;
}

.service-card:hover {
  transform: translateY(-8px);
}

.service-title {
  font-weight: bold;
}

.service-description {
  font-size: 1rem;
  color: #666;
}

.service-image {
  width: 100%;
  border-radius: 10px;
  margin-top: 15px;
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.facility-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  height: 270px;
}

.carousel-inner {
  padding: 20px;
  text-align: center;
  font-style: italic;
  font-size: 1.1rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #003366;
}
@media (max-width: 768px) {
  .mainh1 {
    font-size: 2rem;
  }

  .mainh2 {
    font-size: 1.5rem;
  }

  .mainh3 {
    font-size: 1.2rem;
  }

  .service-card {
    margin: 15px 0;
    padding: 15px;
  }

  .service-image {
    width: 100%;
    margin-top: 10px;
  }

  .facilities .row {
    margin-left: 0;
    margin-right: 0;
  }

  .facility-card {
    margin: 10px 0;
    padding: 15px;
  }

  .surgery-details ul {
    padding-left: 15px;
  }

  .carousel-inner {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .mainh1 {
    font-size: 1.8rem;
  }

  .mainh2 {
    font-size: 1.4rem;
  }

  .service-card {
    margin: 10px 0;
    padding: 10px;
  }

  .service-image {
    width: 100%;
    margin-top: 10px;
  }

  .mainh3 {
    font-size: 1.1rem;
  }
}
</style>
