<template>
  <div class="laboratory-pathology">
    <div class="container">
      <header class="header text-center mb-5">
        <p class="mainh1">{{ $t('laboratoryTitle780') }}</p>
        <p class="lead">{{ $t('laboratoryDescription781') }}</p>
      </header>

      <section class="overview mb-5">
        <p class="mainh2">{{ $t('departmentOverview782') }}</p>
        <p>{{ $t('departmentInfo783') }}</p>
      </section>

      <section class="tests">
        <p class="mainh2">{{ $t('testsTitle784') }}</p>
        <div class="row">
          <div class="col-md-4">
            <div class="test-card">
              <p class="mainh3">{{ $t('histology785') }}</p>
              <p>{{ $t('histologyInfo786') }}</p>
              <img :src="img2" alt="Histology" class="test-image fade-in" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="test-card">
              <p class="mainh3">{{ $t('cryoHistology787') }}</p>
              <p>{{ $t('cryoHistologyInfo788') }}</p>
              <img :src="img6" alt="Cryo Freezing" class="test-image fade-in" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="test-card">
              <p class="mainh3">{{ $t('immunohistochemistry789') }}</p>
              <p>{{ $t('immunohistochemistryInfo790') }}</p>
              <img :src="img4" alt="Immunohistochemistry" class="test-image fade-in" />
            </div>
          </div>
        </div>
      </section>

      <section class="biochemical-tests mt-5">
        <p class="mainh3">{{ $t('biochemicalTests791') }}</p>
        <p>{{ $t('biochemicalTestsInfo792') }}</p>
      </section>

      <section class="histological-examination mt-5">
        <p class="mainh2">{{ $t('histology785') }}</p>
        <p>{{ $t('histologyInfo786') }}</p>
        <img :src="img2" alt="Biopsy" class="test-image fade-in" />
      </section>

      <section class="biopsy-methods mt-5">
        <p class="mainh2">{{ $t('biopsyMethods793') }}</p>
        <div class="row">
          <div class="col-md-4">
            <div class="biopsy-card">
              <p class="mainh3">{{ $t('punctuation794') }}</p>
              <p>{{ $t('punctuationInfo795') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="biopsy-card">
              <p class="mainh3">{{ $t('aspiration796') }}</p>
              <p>{{ $t('aspirationInfo797') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="biopsy-card">
              <p class="mainh3">{{ $t('pinch798') }}</p>
              <p>{{ $t('pinchInfo799') }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="reporting mt-5">
        <p class="mainh2">{{ $t('reporting800') }}</p>
        <p>{{ $t('reportingInfo801') }}</p>
      </section>

      <section class="special-conditions mt-5">
        <p class="mainh2">{{ $t('specialConditions802') }}</p>
        <p>{{ $t('specialConditionsInfo803') }}</p>
      </section>
    </div>
  </div>
</template>
  
  <script>
  import img2 from '@/assets/jarrohlik2.jpg'
  import img3 from '@/assets/lab1.jpg'
  import img4 from '@/assets/lab2.jpg'
  import img5 from '@/assets/lab3.jpg'
  import img6 from '@/assets/lab4.jpg'
  export default {
    data(){
        return{
            img2,
            img3,
            img4,
            img4,
            img5,
            img6
        }
    }
    
  };
  </script>
  
  <style scoped>
.laboratory-pathology {
    padding: 30px 0;
  }
  
    .mainh1{
        font-size: 2.5rem;
        color: #003366;
    }
    .mainh2{
    font-size: 2rem;
    color: #003366;
    }
    
    .mainh3 {
        color: #333;
        font-size: 1.8rem;
    }
  
  /* Set a fixed height for the cards */
  .test-card, .reporting-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 450px; /* Set a fixed height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    margin: 30px 0;/* To ensure the content is spaced out evenly */
  }
  .biopsy-card{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 200px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    margin: 30px 0;

  }
  
  .test-card:hover, .biopsy-card:hover {
    transform: translateY(-10px);
  }
  
  .service-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Image Styling */
  .test-image, .img-fluid {
    width: 100%;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Layout */
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
  
  .row {
    margin-bottom: 30px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  @media (max-width: 1024px) {
    .mainh1 {
      font-size: 2rem;
    }
  
    .mainh2 {
      font-size: 1.8rem;
    }
  
    .mainh3 {
      font-size: 1.5rem;
    }
  
    /* Stack cards for tablets */
    .test-card,
    .biopsy-card {
      height: auto;
      margin: 15px 0;
      
    }
    .test-card{
      height: 400px;
    }
  
    .biopsy-card{
      height: 250px;
    }
    /* Adjust padding */
    .laboratory-pathology {
      padding: 20px 10px;
    }
  }
  
  /* For Mobile Phones */
  @media (max-width: 768px) {
    .mainh1 {
      font-size: 1.7rem;
    }
  
    .mainh2 {
      font-size: 1.5rem;
    }
  
    .mainh3 {
      font-size: 1.3rem;
    }
  
    /* Stack cards for mobile */
    .test-card,
    .biopsy-card {
      height: auto;
      margin: 10px 0;
    }
    
  
    /* Adjust image sizes */
    .test-image {
      max-width: 100%;
      height: auto;
    }
  
    /* Reduce padding for mobile */
    .laboratory-pathology {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    /* For small devices like phones in portrait mode */
    .mainh1 {
      font-size: 1.5rem;
    }
  
    .mainh2 {
      font-size: 1.4rem;
    }
  
    .mainh3 {
      font-size: 1.2rem;
    }
  
    .test-card,
    .biopsy-card {
      padding: 10px;
      margin: 5px 0;
    }
  }
  </style>
  