<template>
  <div class="container">
    <p class="title">{{ t("ourservice") }}</p>
    <div class="card-list">
      <div class="card" v-for="(item, index) in items" :key="index">
        <a :href="item.link">
          <div class="card-content">
            <div class="card-text">
              <p class="text">{{ item.text }}</p>
            </div>
          </div>
          <div class="card-action">
            <button class="action-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                class="arrow-icon"
              >
                <path
                  d="M9 18l6-6-6-6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

// Access translation function
const { t } = useI18n();

// Define items list using `ref`
const items = ref([
  { text: t("klinik_diagnostik_laboratoriya"), link: "/firstpage" },
  { text: t("kompyuter_tomografiya"), link: "/secondpage" },
  { text: t("rentgen_tadqiqotlari"), link: "/thirdpage" },
  { text: t("ultrasonik_tadqiqotlar"), link: "/fourthpage" },
  { text: t("1_soat_narkos"), link: "/fifthpage" },
  { text: t("bosh_va_boyin_osmalari_bolimi"), link: "/sixthpage" },
]);
</script>

<style lang="scss" scoped>
.card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 10px;
}

.text {
  font-size: 18px;
}

.title {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin: 50px 0;
}

.card {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  padding: 15px;
  border: 1px solid #9e9e9e;
  background-color: #fff;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100%;
  background-color: #06829b;
  transition: bottom 0.5s ease-out;
  z-index: -1;
}

.card:hover::before {
  bottom: 0;
  animation: slideBackground 0.5s ease-out forwards;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  color: #fff;
}

.card a {
  display: flex;
  align-items: center; /* Vertically center the text and icon */
  justify-content: center; /* Horizontally center the text and icon */
  text-align: start;
  gap: 10px;
}

.card:hover {
  .arrow-icon {
    stroke: #fff;
    border: 1px solid #fff;
  }

  .card-text p {
    color: #fff;
  }
}

.card-content {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  gap: 10px;
  flex-grow: 1;
}

.card-text p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  word-wrap: break-word;
  line-height: 1.4;
  justify-content: flex-start;
}

.card-action {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.action-button:hover {
  transform: translateX(5px);
}

.arrow-icon {
  width: 20px;
  height: 20px;
  stroke: #9e9e9e;
  border: 1px solid #9e9e9e;
  border-radius: 50%;
}

@keyframes slideBackground {
  0% {
    bottom: 100%;
  }
  100% {
    bottom: 0;
  }
}

@media (max-width: 1024px) {
  .card-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .card a {
    padding-top: 14px;
  }
}

@media (max-width: 768px) {
  .card-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .title {
    font-size: 36px;
  }

  .card {
    padding: 10px;
  }

  .card-text p {
    font-size: 13px;
  }

  .card a {
    padding-bottom: 10px;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .card-list {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .card {
    height: 60px;
    padding: 10px;
  }

  .card-text p {
    font-size: 12px;
  }

  .arrow-icon {
    width: 16px;
    height: 16px;
  }

  .action-button {
    padding: 4px;
  }
}
</style>
