<template>
  <div class="container">
    <p class="bosh-h1">{{ $t('boshqaruv') }}</p>

    <!-- Loop through profiles manually -->
    <div v-for="index in 4" :key="index" class="main card">
      <div class="card-main">
        <router-link :to="`/profile/${index}`">
          <div class="profile-img">
            <img 
              :src="getProfileImage(index)" 
              :alt="'Profile image of ' + $t(`name${index}`)" 
              loading="lazy" />
          </div>
        </router-link>
        <div class="profile-info">
          <p class="name">{{ $t(`name${index}`) }}</p>
          <p class="position">{{ $t(`position${index}`) }}</p>
        </div>
        <div>
          <div class="icon-container">
            <div class="icon-item">
              <div><i class="fa fa-map-marker"></i></div>
              <div><p>{{ $t(`location${index}`) }}</p></div>
            </div>
            <div class="icon-item">
              <div><i class="fa fa-phone"></i></div>
              <div><p>{{ $t(`phone${index}`) }}</p></div>
            </div>
            <div class="icon-item">
              <div><i class="fa fa-clock"></i></div>
              <div><p>{{ $t(`schedule${index}`) }}</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

// Importing images for profiles
import Gafurov from '@/assets/doctor/gafurov.jpg';
import Dustov from '@/assets/doctor/dustov.jpg';
import Saidov from '@/assets/doctor/saidov.jpg';
import Abdukarimov from '@/assets/doctor/abdu.jpg';

const { t } = useI18n();

function getProfileImage(index) {
  switch(index) {
    case 1: return Saidov;
    case 2: return Dustov;
    case 3: return Abdukarimov;
    case 4: return Gafurov;
    default: return '';
  }
}
</script>

<style scoped>
  /* General styles */
  .bosh-h1 {
    font-size: 2.5rem;
    color: #3f4e5c;
    text-align: center;
    font-weight: bold;
    margin-bottom: 3rem;
    letter-spacing: 2px;
    margin-top: 40px;
  }

  .name {
    font-size: 2.6rem;
    text-align: center;
    font-weight: 600;
  }

  .position {
    font-size: 1.25rem;
    text-align: center;
    font-weight: 600;
    color: #06829b;
  }

  .main.card {
    background-color: #ffffff;
    border-radius: 15px;
    margin-bottom: 4rem;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .main.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .card-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }

  .profile-img img {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 5px solid #06829b;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  .profile-img img:hover {
    transform: scale(1.1);
  }

  .profile-info {
    text-align: center;
    flex-grow: 1;
    width: 50%;
  }

  .icon-container {
    align-items: center;
  }

  .icon-item {
    display: flex;
    gap: 10px;
    color: #333;
    transition: transform 0.3s ease;
  }

  .icon-item:hover {
    transform: translateX(5px);
  }

  .icon-item p {
    font-size: 1rem;
    color: #333;
  }

  .icon-item i {
    font-size: 16px;
    color: #06829b;
    transition: transform 0.3s ease;
  }

  .icon-item i:hover {
    transform: scale(1.3);
  }

  /* Responsive design */
  @media (max-width: 1024px) {
    .card-main {
      flex-direction: column;
      text-align: center;
    }

    .icon-container {
      justify-content: center;
      gap: 20px;
    }

    .profile-img img {
      width: 180px;
      height: 180px;
    }

    .bosh-h1 {
      font-size: 2rem;
    }
  }

  @media (max-width: 768px) {
    .card-main {
      padding: 1.25rem;
    }

    .profile-img img {
      width: 160px;
      height: 160px;
    }

    .bosh-h1 {
      font-size: 1.75rem;
    }

    .name {
      font-size: 1.5rem;
    }

    .position {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    .icon-container {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }

    .profile-img img {
      width: 150px;
      height: 150px;
    }

    .bosh-h1 {
      font-size: 1.5rem;
    }

    .icon-item p {
      font-size: 0.875rem;
    }
  }
</style>
