<template>
  <div class="container">
    <p class="mainh1">{{ $t("ourworkers") }}</p>
    <div class="vrachlar">
      <div
        v-for="(profile, index) in paginatedProfiles"
        :key="index"
        class="card"
      >
        <a :href="profile.link">
          <div class="profile-img">
            <img :src="profile.image" :alt="profile.name" />
          </div>
        </a>
        <div class="card-info">
          <p class="name">{{ profile.name }}</p>
          <h3>{{ profile.position }}</h3>
        </div>
      </div>
    </div>

    <div class="pagination">
      <button
        :disabled="currentPage === 1"
        @click="goToPage(currentPage - 1)"
      >
        &lt;
      </button>
      <span>
        {{ $t("Page") }} {{ currentPage }} {{ $t("of") }} {{ totalPages }}
      </span>
      <button
        :disabled="currentPage === totalPages"
        @click="goToPage(currentPage + 1)"
      >
        &gt;
      </button>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

// Import images for the profiles
import Uchqun from "@/assets/doctor/abdukarimov.jpg";
import Erkin from "@/assets/doctor/abdullaev_erkin.jpg";
import Maya from "@/assets/doctor/abdullaeva_maya.jpg";
import Saodat from "@/assets/doctor/abdullaeva_saodat.jpg";
import Dilnoza from "@/assets/doctor/amrullaeva_dilnoza.jpg";
import Baxriddin from "@/assets/doctor/asadov_baxriddin.jpg";
import Anvar from "@/assets/doctor/tulaev_anvar.jpg";
import Yaxe from "@/assets/doctor/xakimov_yahe.jpg";
import Shaxob from "@/assets/doctor/azimov_shaxob.jpg";
import Bafo from "@/assets/doctor/bafo_bakaevich.jpg";
import Shukur from "@/assets/doctor/bakoev_shukur.jpg";
import Ahtam from "@/assets/doctor/barnoev_axtam.jpg";
import Shuxrat from "@/assets/doctor/boboev_shuxrat.jpg";
import Mashrab from "@/assets/doctor/boltaev_mashrab.jpg";
import Eler from "@/assets/doctor/choriev_eler.jpg";
import Sherali from "@/assets/doctor/dustov_sherali.jpg";
import Furqar from "@/assets/doctor/haydarov_furkat.jpg";
import Dildora from "@/assets/doctor/ishankulova_dildora.jpg";
import Muxarram from "@/assets/doctor/islamova_muxarram.jpg";
import Doniyor from "@/assets/doctor/izatullaev_donier.jpg";
import Muattar from "@/assets/doctor/karimova_muattar.jpg";
import Shahob from "@/assets/doctor/kushaev_shaxob.jpg";
import Baxrom from "@/assets/doctor/latipov_baxrom.jpg";
import Nasiba from "@/assets/doctor/maksudova_nasiba.jpg";
import Umid from "@/assets/doctor/mamedov_umid_sunnatovich.jpg";
import Yusuf from "@/assets/doctor/murodov_yusuf.jpg";
import Laylo from "@/assets/doctor/muxitdinova_laylo.jpg";
import Faxriddin from "@/assets/doctor/muzaffarov_faxriddin.jpg";
import Firuza from "@/assets/doctor/nabieva_firuza.jpg";
import Bekzod from "@/assets/doctor/naimov_bekzod.jpg";
import Nurbek from "@/assets/doctor/nazarov_nurbek.jpg";
import Jaxongir from "@/assets/doctor/nematov_jaxongir.jpg";
import Xalim from "@/assets/doctor/ochilov_xalim.jpg";
import Dilsoz from "@/assets/doctor/otaboeva_dilsuz.jpg";
import Bekzodd from "@/assets/doctor/rahimov_bekzod.jpg";
import Akmal from "@/assets/doctor/ruziev_akmal.jpg";
import Firuzaa from "@/assets/doctor/ruzieva_firuza.jpg";
import Shakhlo from "@/assets/doctor/ruzieva_shaxlo.jpg";
import Fayzulloo from "@/assets/doctor/salihov_fayz.jpg";
import Abdulaziz from "@/assets/doctor/shamsitdinov_abdulaziz.jpg";
import Mirshod from "@/assets/doctor/sharipov_mirshod.jpg";
import Mustafo from "@/assets/doctor/shirinov_mustafo.jpg";
import Dilbar from "@/assets/doctor/sharipova_dilbar.jpg";
import Saodatt from "@/assets/doctor/sharipova_saodat.jpg";
import Zamon from "@/assets/doctor/shukurov_zamon.jpg";
import Jorabek from "@/assets/doctor/togaev_jurabek.jpg";
import Bobir from "@/assets/doctor/ergashev_bobir.jpg";
import Nodir from "@/assets/doctor/ergashev_nodir.jpg";
import Mahmud from "@/assets/doctor/xamroev_maxmud.jpg";
import Muyassar from "@/assets/doctor/xamroeva_muyassar.jpg";
import Gulhayo from "@/assets/doctor/xamrokulova_gulhaye.jpg";
import Nasibaa from "@/assets/doctor/xasanova_nasiba.jpg";
import Alimardon from "@/assets/doctor/xayitov_alimardon.jpg";

const profiles = [
  {
    image: Uchqun,
    name: "Abdukarimov Uchqun",
    position: t("Birinchi jarroxlik"),
    link: "uchqun",
  },
  {
    image: Erkin,
    name: "Abdullaev Erkin",
    position: t("Shifokor"),
    link: "/erkin",
  },
  { image: Maya, name: "Abdullaeva Maya", position: t("Shifokor"), link: "/maya" },
  {
    image: Saodat,
    name: "Abdullaeva Saodat",
    position: t("Birinchi jarroxlik"),
    link: "saodat",
  },
  {
    image: Dilnoza,
    name: "Amrullaeva Dilnoza",
    position: t("Ikkinchi toifali radioterapevt shifokori"),
    link: "/dilnoz",
  },
  {
    image: Baxriddin,
    name: "Asadov Baxriddin",
    position: t("Ikkinchi jarroxlik"),
    link: "/baxriddin",
  },
  {
    image: Anvar,
    name: "Tolaev Anvar",
    position: t("Ikkinchi jarroxlik"),
    link: "/anvar",
  },
  {
    image: Yaxe,
    name: "Xakimov Yahe",
    position: t("To’rtinchi jarroxlik"),
    link: "/yahe",
  },
  {
    image: Shaxob,
    name: "Azimov Shahob",
    position: t("Birinchi jarroxlik"),
    link: "/shaxob",
  },
  {
    image: Bafo,
    name: "Yo'ldashev Bafo",
    position: t("Palliative"),
    link: "/bafo",
  },
  {
    image: Shukur,
    name: "Bakoev Shukur",
    position: t("Palliative"),
    link: "/shukur",
  },
  {
    image: Ahtam,
    name: "Barnoev Axtam",
    position: t("Nurli diagnosyika va radiologiya"),
    link: "/adham",
  },
  {
    image: Shuxrat,
    name: "Boboev Shuxrat",
    position: t("Palliative"),
    link: "/shuxrat",
  },
  {
    image: Mashrab,
    name: "Boltaev Mashrab",
    position: t("Ikkinchi jarroxlik"),
    link: "/mashrab",
  },
  {
    image: Eler,
    name: "Choriev Eler",
    position: t("Ikkinchi jarroxlik"),
    link: "/eler",
  },
  {
    image: Sherali,
    name: "Dustov Sherali",
    position: t("Shifokor"),
    link: "/sherali",
  },
  {
    image: Furqar,
    name: "Haydarov Furqat",
    position: t("3 jarrohlik"),
    link: "/furqat",
  },
  {
    image: Dildora,
    name: "Ishanqulova Dildora",
    position: t("1 jarrohlik"),
    link: "/dildora",
  },
  {
    image: Muxarram,
    name: "Ismailova Muharram",
    position: t("1 jarrohlik"),
    link: "/muharram",
  },
  {
    image: Doniyor,
    name: "Izatullayev Doniyor",
    position: t("3 jarrohlik"),
    link: "/doniyor",
  },
  {
    image: Muattar,
    name: "Karimova Muattar",
    position: t("Birinchi toifali radioterapevt"),
    link: "/Karimova",
  },
  {
    image: Shahob,
    name: "Kushayev Shaxob",
    position: t("Nurli diagnocti va radiologiya"),
    link: "/shahob",
  },
  {
    image: Baxrom,
    name: "Latipov Baxrom",
    position: t("1 jarrohlik"),
    link: "/Baxrom",
  },
  {
    image: Umid,
    name: "Mamedov Umid",
    position: t("3 jarrohlik"),
    link: "/Umid",
  },
  {
    image: Yusuf,
    name: "Murodov Yusuf",
    position: t("Vrach radioterapevt"),
    link: "/Yusuf",
  },
  {
    image: Laylo,
    name: "Muxitdinnova Laylo",
    position: t("1 jarrohlik"),
    link: "/Laylo",
  },
  {
    image: Faxriddin,
    name: "Muzaffarov Faxriddin",
    position: t("Muhandist-texnalog"),
    link: "/Faxriddin",
  },
  {
    image: Firuza,
    name: "Nabiyeva Firuza",
    position: t("Poliklinika"),
    link: "/Firuza",
  },
  {
    image: Bekzod,
    name: "Naimov Bekzod",
    position: t("3 jarrohlik"),
    link: "/Bekzod",
  },
  {
    image: Nurbek,
    name: "Nazarov Nurbek",
    position: t("Palliativ"),
    link: "/Nurbek",
  },
  {
    image: Jaxongir,
    name: "Nematjonov Jahongir",
    position: t("Palliativ"),
    link: "/Jahongir",
  },
  {
    image: Xalim,
    name: "Ochilov Halim",
    position: t("Palliativ"),
    link: "/Halim",
  },
  {
    image: Dilsoz,
    name: "Otaboyeva Dilsuz",
    position: t("Shifokor"),
    link: "Dilsuz",
  },
  {
    image: Bekzodd,
    name: "Raximov Bekzod",
    position: t("Palliativ"),
    link: "Bekzodd",
  },
  {
    image: Akmal,
    name: "Ro'ziyev Akmal",
    position: t("Poliklinika"),
    link: "Akmal",
  },
  {
    image: Firuzaa,
    name: "Ro'ziyeva Firuza",
    position: t("Laboratoriya"),
    link: "/Firuza",
  },
  {
    image: Shakhlo,
    name: "Ro'ziyeva Shahlo",
    position: t("Poliklinika"),
    link: "/Shahlo",
  },
  {
    image: Fayzulloo,
    name: "Salihov Fayzullo",
    position: t("Kimyovir terapiya"),
    link: "/Fayzullo",
  },
  {
    image: Abdulaziz,
    name: "Shamsitdinov Abdulaziz",
    position: t("Muhandis-texnalog"),
    link: "/Abdulaziz",
  },
  {
    image: Mirshod,
    name: "Sharipov Mirshod",
    position: t("Poliklinika"),
    link: "/Mirshod",
  },
  {
    image: Mustafo,
    name: "Shirinov Mustafo",
    position: t("Radiolog vrach"),
    link: "/Mustafo",
  },
  {
    image: Dilbar,
    name: "Sharipova Dilbar",
    position: t("Shifokor"),
    link: "/sharipova-dilbar",
  },
  {
    image: Saodatt,
    name: "Sharipova Saodat",
    position: t("Shifokor"),
    link: "/sharipova-saodat",
  },
  {
    image: Zamon,
    name: "Shukurov Zamon",
    position: t("Nurli diagnostika va radiologiya"),
    link: "/Zamon",
  },
  {
    image: Jorabek,
    name: "Tog'ayev Jo'rabek",
    position: t("Ikkinchi toifali radioterapevt shifokori"),
    link: "/Jurabek",
  },
  {
    image: Bobir,
    name: "Ergashev Bobur",
    position: t("Poliklinika"),
    link: "/Bobir",
  },
  {
    image: Nodir,
    name: "Ergashev Nodir",
    position: t("Vrach onkoradiolog"),
    link: "/Nodir",
  },
  {
    image: Mahmud,
    name: "Hamroyev Mahmud",
    position: t("Shifokor"),
    link: "/Mahmud",
  },
  {
    image: Muyassar,
    name: "Hamroyeva Muyassar",
    position: t("Shifokor"),
    link: "/Muyassar",
  },
  {
    image: Gulhayo,
    name: "Hamroqulova Gulhayo",
    position: t("Shifokor"),
    link: "/Gulhayo",
  },
  {
    image: Nasibaa,
    name: "Hasanova Nasiba",
    position: t("Poliklinika"),
    link: "/nasibaa",
  },
  {
    image: Alimardon,
    name: "Hayitov Alimardon",
    position: t("Poliklinika"),
    link: "/alimardon",
  },
  {
    image: Nasiba,
    name: "Maqsudova Nasiba",
    position: t("Poliklinika"),
    link: "/Nasiba",
  },
];

const currentPage = ref(1);
const itemsPerPage = 6;

const totalPages = computed(() => Math.ceil(profiles.length / itemsPerPage));

const paginatedProfiles = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return profiles.slice(start, end);
});

const goToPage = (page) => {
  if (page < 1 || page > totalPages.value) return;
  currentPage.value = page;
};
</script>
  
  <style scoped>
.mainh1 {
  font-size: 3em;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.name {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.vrachlar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
}

.card {
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.4s ease;
  margin-top: 30px;
  max-width: 350px;
  width: 100%;
}

.card a{
  width: 100%;
  display: flex;
  justify-content: center;
}

.card .profile-img {
  position: relative;
  width: 300px;
  height: 300px;
}

.card .profile-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  border: 5px solid #06829b;
}

.card-info {
  margin-top: 20px;
}

.card-info h1 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-top: 15px;
}

.card-info h3 {
  font-size: 16px;
  color: #06829b;
  margin-top: 5px;
  line-height: 1.4;
}

.card:hover {
  transform: translateY(-12px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 30px 0;
}

.pagination button {
  padding: 10px 20px;
  background-color: #06829b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #06829b;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  align-self: center;
}

/* Tablet and smaller screens */
@media (max-width: 1024px) {
  .vrachlar {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    padding: 15px;
    max-width: 100%;
  }

  .card .profile-img img {
    height: 270px;
  }

  .name {
    font-size: 20px;
  }
}

/* Mobile screens */
@media (max-width: 768px) {
  .vrachlar {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .card {
    width: 90%;
    padding: 15px;
    margin-left: 17px;
  }

  .profile-img img {
    height: 220px;
  }

  .name {
    font-size: 18px;
  }

  .card-info h1 {
    font-size: 18px;
  }

  .card-info h3 {
    font-size: 14px;
  }
}

</style>
  
  