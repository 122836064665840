<template>
  <div class="container">
    <div class="main">
      <p class="title">{{ $t('news') }}</p>

      <div class="content-wrapper">
        <div
          v-for="(news, index) in paginatedNews"
          :key="index"
          class="news-card"
        >
          <a :href="news.link" class="news-card__card-link"></a>
          <img :src="news.image" :alt="news.title" class="news-card__image" />
          <div class="news-card__text-wrapper">
            <p class="news-card__title">{{ news.title }}</p>
            <div class="news-card__post-date">{{ news.date }}</div>
            <div class="news-card__details-wrapper">
              <p class="news-card__excerpt">{{ news.excerpt }}</p>
              <a :href="news.link" class="read-more">
                {{$t('read_more')}} <i class="fas fa-long-arrow-alt-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination">
        <button
          class="btn btn-secondary"
          :disabled="currentPage === 1"
          @click="changePage(currentPage - 1)"
        >
          &lt;
        </button>

        <span>{{ $t('page') }} {{ currentPage }} {{ $t('of') }} {{ totalPages }}</span>

        <button
          class="btn btn-secondary"
          :disabled="currentPage === totalPages"
          @click="changePage(currentPage + 1)"
        >
          &gt;
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import newsImage from '@/assets/image3.png'; 
import newsImage1 from '@/assets/image1.webp'; 

import { useI18n } from "vue-i18n";

const { t } = useI18n();

const newsItems = ref([
  {
    title: t("official_statement"),
    date: t("date1"),
    image: newsImage,
    link: "/firstnews",
    excerpt: "Sample excerpt for the first news item.",
  },
  {
    title: t("headline"),
    date: t("date"),
    image: newsImage1,
    link: "/secondnews",
    excerpt: "Sample excerpt for the second news item.",
  },
  {
    title: t("official_statement"),
    date: t("date1"),
    image: newsImage,
    link: "/firstnews",
    excerpt: "Sample excerpt for the first news item.",
  },
  {
    title: t("headline"),
    date: t("date"),
    image: newsImage1,
    link: "/secondnews",
    excerpt: "Sample excerpt for the second news item.",
  },
  {
    title: t("official_statement"),
    date: t("date1"),
    image: newsImage,
    link: "/firstnews",
    excerpt: "Sample excerpt for the first news item.",
  },
  {
    title: t("headline"),
    date: t("date"),
    image: newsImage1,
    link: "/secondnews",
    excerpt: "Sample excerpt for the second news item.",
  },
  {
    title: t("official_statement"),
    date: t("date1"),
    image: newsImage,
    link: "/firstnews",
    excerpt: "Sample excerpt for the first news item.",
  },
  {
    title: t("headline"),
    date: t("date"),
    image: newsImage1,
    link: "/secondnews",
    excerpt: "Sample excerpt for the second news item.",
  },
]);

const currentPage = ref(1);
const itemsPerPage = 6;

const totalPages = computed(() =>
  Math.ceil(newsItems.value.length / itemsPerPage)
);

const paginatedNews = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return newsItems.value.slice(startIndex, endIndex);
});

function changePage(page) {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 42px;
  text-align: center;
  font-weight: 700;
  margin: 50px 0;
  color: #333;
}

.pagination {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  button {
    font-size: 1rem;
    padding: 8px 12px;
    background-color: #06829b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
      cursor: not-allowed;
      background-color: #ccc;
    }

    &:not(:disabled):hover {
      background-color: #035464;
    }
  }

  span {
    font-size: 1rem;
    color: #555;
  }
}

.news-card__text-wrapper {
  height: 120px;
}

.content-wrapper {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.5rem;
}

.news-card {
  position: relative;
  height: 18rem;
  overflow: hidden;
  border-radius: 0.5rem;
  flex: 1;
  min-width: 290px;
  margin: 1rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  }

  &__card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 3s ease;
    position: relative;
    z-index: -1;
  }

  &__text-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    transition: background-color 0.3s ease;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  &__post-date {
    font-size: 0.8rem;
    color: #ccc;
    margin-bottom: 0.5rem;
  }

  &__details-wrapper {
    max-height: 0;
    opacity: 0;
    transition: max-height 1.5s ease, opacity 1s ease;
  }

  &:hover {
    &__details-wrapper {
      max-height: 20rem;
      opacity: 1;
    }
  }

  &__excerpt {
    font-weight: 300;
  }

  &__read-more {
    background: #ffcc00;
    color: #333;
    display: block;
    padding: 0.4rem 0.6rem;
    border-radius: 0.3rem;
    margin-top: 1rem;
    border: 1px solid #444;
    font-size: 0.9rem;
    text-decoration: none;
    width: 8rem;
    margin-left: auto;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background: #333;
      color: #ffcc00;
    }

    i {
      margin-left: 0.3rem;
      transition: left 0.3s ease, color 0.3s ease;
    }

    &:hover i {
      left: 0.5rem;
      color: #ffcc00;
    }
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 36px;
  }

  .content-wrapper {
    max-width: 90%;
  }

  .news-card {
    height: 16rem;
    min-width: 250px;
    flex: 1 0 45%;
    margin: 1rem;
  }

  .news-card__title {
    font-size: 1.1rem;
  }

  .pagination {
    gap: 10px;
  }

  .pagination button {
    padding: 7px 10px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 32px;
  }

  .content-wrapper {
    max-width: 100%;
  }

  .news-card {
    height: 14rem;
    flex: 1 0 48%;
  }

  .news-card__title {
    font-size: 1rem;
  }

  .pagination {
    gap: 15px;
  }

  .pagination button {
    padding: 6px 8px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .content-wrapper {
    max-width: 100%;
  }

  .news-card {
    height: 12rem;
    flex: 1 0 100%;
    margin: 0.5rem;
  }

  .news-card__title {
    font-size: 0.9rem;
  }

  .news-card__post-date {
    font-size: 0.7rem;
  }

  .news-card__read-more {
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
    width: auto;
  }

  .pagination {
    gap: 10px;
  }

  .pagination button {
    padding: 5px 6px;
    font-size: 0.8rem;
  }
}
</style>
