import { createStore } from 'vuex';

export default createStore({
  state: {
    selectedLanguage: localStorage.getItem('locale') || 'uz', // Get the saved language from localStorage or default to 'uz'
  },
  getters: {
    // Getter to get the current selected language
    getSelectedLanguage(state) {
      return state.selectedLanguage;
    },
  },
  mutations: {
    // Mutation to change the language
    setLanguage(state, lang) {
      state.selectedLanguage = lang;
      localStorage.setItem('locale', lang); // Store the selected language in localStorage
    },
  },
  actions: {
    // Action to commit the language change
    changeLanguage({ commit }, lang) {
      commit('setLanguage', lang);
    },
  },
  modules: {},
});
