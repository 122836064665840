<template>
  <div class="container">
      <div class="palliative-care-container">
          <!-- Header Section -->
          <header class="header text-center">
            <h1 class="display-4">{{ $t('palliativeCareTitle820') }}</h1>
            <p class="lead">{{ $t('palliativeCareDescription821') }}</p>
          </header>
      
          <!-- Palliative Care Overview Section -->
          <section class="overview">
            <h2 class="section-title fade-in">{{ $t('overviewTitle822') }}</h2>
            <p class="fade-in">{{ $t('overviewDescription823') }}</p>
            <div class="image-container fade-in">
              <img :src="img2" alt="Palliative Care Image" class="responsive-img" />
            </div>
          </section>
      
          <!-- Hospital Rooms Section -->
          <section class="rooms">
            <h2 class="section-title fade-in">{{ $t('hospitalRoomsTitle824') }}</h2>
            <div class="room-cards fade-in">
              <div class="room-card">
                <h3>{{ $t('room1Title825') }}</h3>
                <p>{{ $t('room1Description826') }}</p>
              </div>
              <div class="room-card">
                <h3>{{ $t('room2Title827') }}</h3>
                <p>{{ $t('room2Description828') }}</p>
              </div>
              <div class="room-card">
                <h3>{{ $t('room3Title829') }}</h3>
                <p>{{ $t('room3Description830') }}</p>
              </div>
            </div>
          </section>
      
          <!-- Medical Services Section -->
          <section class="medical-services">
            <h2 class="section-title fade-in">{{ $t('medicalServicesTitle831') }}</h2>
            <div class="service-cards fade-in">
              <div class="service-card">
                <h3>{{ $t('service1Title832') }}</h3>
                <p>{{ $t('service1Description833') }}</p>
              </div>
              <div class="service-card">
                <h3>{{ $t('service2Title834') }}</h3>
                <p>{{ $t('service2Description835') }}</p>
              </div>
              <div class="service-card">
                <h3>{{ $t('service3Title836') }}</h3>
                <p>{{ $t('service3Description837') }}</p>
              </div>
            </div>
          </section>
      
          <!-- Specialized Areas of Care Section -->
          <section class="specialized-care">
            <h2 class="section-title fade-in">{{ $t('specializedCareTitle838') }}</h2>
            <ul class="care-list fade-in">
              <li>{{ $t('care1') }}</li>
              <li>{{ $t('care2') }}</li>
              <li>{{ $t('care3') }}</li>
              <li>{{ $t('care4') }}</li>
              <li>{{ $t('care5') }}</li>
            </ul>
          </section>
      
          <!-- Treatment Focus Section -->
          <section class="treatment-focus">
            <h2 class="section-title fade-in">{{ $t('treatmentFocusTitle839') }}</h2>
            <p class="fade-in">{{ $t('treatmentFocusDescription840') }}</p>
          </section>

          <!-- Malignant Tumors Section -->
          <section class="malignant-tumors">
            <h2 class="section-title fade-in">{{ $t('malignantTumorsTitle841') }}</h2>
            <p class="fade-in">{{ $t('malignantTumorsDescription842') }}</p>
          </section>
      </div>
  </div>    
</template>


<script>
import img2 from '@/assets/poliative.jpg';

export default {
data() {
  return {
    img2
  };
}
};
</script>

<style scoped>
.palliative-care-container {
padding: 50px 0;
font-family: 'Roboto', sans-serif;
}

/* Header */
.header {
text-align: center;
margin-bottom: 40px;
}

.rooms{
  margin: 30px 0;
}

h1 {
font-size: 2.5rem;
color: #003366;
font-weight: 700;
}

p.lead {
font-size: 1.2rem;
color: #007bff;
font-weight: 500;
}

/* Section Titles */
.section-title {
font-size: 2.5rem;
color: #003366;
text-align: center;
text-transform: uppercase;
margin: 20px 0; 
font-weight: 600;
letter-spacing: 1px;
}

/* Paragraphs */
p {
font-size: 1.15rem;
color: #555;
line-height: 1.6;
text-align: center;
margin-bottom: 20px;
max-width: 800px;
margin-left: auto;
margin-right: auto;
}

/* Fade-in Animation */
.fade-in {
opacity: 0;
animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

/* Image Container for Centering */
.image-container {
display: flex;
justify-content: center;
margin-top: 20px;
}

/* Image */
img.responsive-img {
max-width: 100%;
border-radius: 8px;
box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
transition: transform 0.3s ease;
}

img.responsive-img:hover {
transform: scale(1.05);
}

.room-cards, .service-cards {
display: flex;
gap: 20px;
justify-content: center;
flex-wrap: wrap;
margin: 30px 0;
}

.room-card, .service-card {
background-color: #fff;
padding: 20px;
width: 250px;
text-align: center;
border-radius: 12px;
box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
transition: transform 0.3s ease, box-shadow 0.3s ease;
margin: 30px 0;
}

.room-card:hover, .service-card:hover {
transform: translateY(-10px);
box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

/* Lists */
ul {
list-style-type: none;
padding: 0;
text-align: center;
}

li {
font-size: 1.2rem;
color: #555;
line-height: 1.8;
margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
.section-title {
  font-size: 2rem;
}

p {
  font-size: 1.1rem;
}

.room-card, .service-card {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}
}
</style>
