<template>
    <div id="app">
      <div class="container">
        <div class="main-news">
          <div class="news-article">
            <div class="news-image">
              <img :src="newsImage" alt="News Image" class="news-image-img"/>
            </div>
  
            <div class="article-content">
              <p class="headline">{{ $t('headline') }}</p>
              <p class="time">{{ $t('date') }}</p>
              <p class="summary">{{ $t('summary') }}‌</p>
              
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import newsImage from '../../assets/image3.png';
  export default {
    data() {
      return {
        newsImage, 
      };
    },
  };
  </script>
  
  <style scoped>
 

  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Header Styling */
  .header {
    background-color: #0044cc;
    color: white;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .mainh1 {
    font-size: 34px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .main-news {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
  
  .news-article {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .news-article:hover {
    transform: translateY(-10px);
    opacity: 1;
  }
  
  .news-image-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .article-content {
    color: #333;
  }
  
  .headline {
    font-size: 28px;
    font-weight: bold;
    color: #0044cc;
  }
  
  .time {
    color: #777;
    font-size: 14px;
  }
  
  .summary {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
  }
  
  .read-more {
    background-color: #0044cc;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .read-more:hover {
    background-color: #0033aa;
  }
  
  .footer {
    background-color: #222;
    color: white;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .mainh1 {
      font-size: 28px;
    }
  
    .headline {
      font-size: 24px;
    }
  
    .summary {
      font-size: 14px;
    }
  
    .news-image-img {
      max-width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .mainh1 {
      font-size: 22px;
    }
  
    .headline {
      font-size: 20px;
    }
  
    .summary {
      font-size: 12px;
    }
  }
  </style>
  