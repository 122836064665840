<template>
    <div class="container">
      <p class="mainh1">{{$t('diagnostic_lab')}}</p>
      <div class="test-list">
        <div class="test-item">
          <p class="test-name">{{$t('general_blood_test')}}</p>
          <p class="test-range">36.000 - 72.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('blood_bilirubin')}}</p>
          <p class="test-range">24.000 - 48.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('blood_glucose')}}</p>
          <p class="test-range">24.000 - 48.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('blood_creatinine')}}</p>
          <p class="test-range">24.000 - 48.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('blood_urea')}}</p>
          <p class="test-range">27.000 - 54.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('total_protein')}}</p>
          <p class="test-range">27.000 - 54.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('albumin_blood')}}</p>
          <p class="test-range">27.000 - 54.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('alat')}}</p>
          <p class="test-range">27.000 - 54.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('alpha_amylase')}}</p>
          <p class="test-range">27.000 - 54.000</p>
        </div>
  
        <div class="test-item">
          <p class="test-name">{{$t('bilirubin_blood')}}</p>
          <p class="test-range">27.000 - 54.000</p>
        </div>
      </div>
      
      <div class="service-page">
        <p class="title">{{$t('ourservice')}}</p>
        <div class="content-description">
          <p>
            {{ $t('service_description') }}
          </p>
        </div>
        <a href="../../assets/pfd/list.pdf" class="pdf-button" download>{{$t('download_pdf')}}</a>
    </div>
      </div>
  </template>
  
  <script setup>
  </script>
  
  <style lang="scss" scoped>
  /* Main Heading Styling */
  .mainh1 {
    font-size: 42px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
    color: #007bff;
    text-transform: uppercase;
  }
  
  /* Test List Container */
  .test-list {
    width: 100%;
    display: grid; /* Change to grid layout */
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
    gap: 20px;
    animation: fadeIn 1s ease-out;
  }
  
  /* Test Item Styling */
  .test-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #9E9E9E;
    margin: 5px 0;
  }
  
  /* Hover effect on the test item */
  .test-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  }
  
  /* Test Name Styling */
  .test-name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.4;
    text-transform: capitalize;
    transition: color 0.3s ease;
  }

  .title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  /* Service description text */
  .content-description p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    text-align: center;
    margin: 0 20px;
  }
  
  /* Test Range Styling */
  .test-range {
    font-size: 16px;
    color: #666;
    font-weight: 400;
    transition: color 0.3s ease;
  }
  
  /* Hover effect on the range */
  .test-range:hover {
    color: #06829b;
  }
  
  /* PDF Button Styling (Single button at the bottom) */
  /* PDF Button Styling (Single button at the bottom) */
.pdf-button {
    background-color: #06829b;
    color: white;
    padding: 12px 20px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    width: 300px;  /* Fixed width */
    margin: 30px auto;  /* Center the button horizontally */
    display: block;  /* Make it a block element to apply margin auto */
    transition: background-color 0.3s ease;
  }
  
  .pdf-button:hover {
    background-color: #0056b3;
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .test-list {
      grid-template-columns: 1fr; 
    }
  }
  </style>
  