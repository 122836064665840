<template>
  <div>
    <section class="showcase">
      <div class="image-slider background--zoom">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          :class="{
            active: currentImageIndex === index,
            zooming: zooming && currentImageIndex === index,
          }"
          alt="Showcase"
        />
      </div>
      <div class="overlay">
        <div class="container">
          <p class="pt-5 title">
            {{ $t('showcaseTitle960') }} <br />
            {{ $t('showcaseSubtitle961') }} <br />
            {{ $t('showcaseBranch962') }}
          </p>
          <div class="text-left">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>


export default {
  data() {
    return {
      images: [
        require("@/assets/doctor/main-2.jpg"),
        require("@/assets/doctor/main.jpg"),
        require("@/assets/image3.png"),
        require("@/assets/image1.webp"),
        require("@/assets/image4.avif"),
      ],
      currentImageIndex: 0,
      zooming: false, 
    };
  },
  mounted() {
    this.startImageSlider();
  },
  methods: {
    startImageSlider() {
      setInterval(() => {
        this.zooming = true;

        setTimeout(() => {
          this.currentImageIndex =
            (this.currentImageIndex + 1) % this.images.length;
          this.zooming = false;
        }, 3000); 
      }, 5000); 
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.showcase {
  width: 100%;
  height: 800px;
  margin-top: -100px;
  position: relative;
  color: white;
  text-align: center;

  .image-slider {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 2s ease, transform 2s ease;
      transform-origin: center;
      transform: scale(1); 
    }

    img.active {
      opacity: 1;
      transform: scale(1.1); 
    }

    img.zooming {
      transform: scale(1.2); 
    }
  }
}

.overlay {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.title {
  margin-top: 150px;
  max-width: 600px;
  font-size: 2.5em;
  text-align: left;
  color: #06829b;
}

p {
  margin-top: 8px;
  font-size: 1.2em;
  text-align: left;
  color: #32325d;
}

.navbar-button {
  border: 2px solid #32325d !important;
  color: #32325d;
  border: none;
  padding: 12px 26px;
  font-size: 1em;
  border-radius: 20px;
  cursor: pointer;

  span {
    font-size: 13px;
    font-weight: bold;
  }
}

.navbar-button:hover {
  background-color: #466361;
  color: #ffffff;
  border: none !important;
}
@media only screen and (max-width: 768px) {
  .showcase {
    height: 500px;
  }

  .overlay {
    height: 300px;
    padding-top: 10px;
  }

  .title {
    font-size: 2em;
    margin-top: 20px;
  }

  p {
    font-size: 1em;
  }

  .navbar-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 480px) {
  .showcase {
    height: 400px;
  }

  .overlay {
    height: 250px;
    padding-top: 5px;
  }

  .title {
    font-size: 1.5em;
    margin-top: 10px;
    padding: 0 5px;
  }

  p {
    font-size: 0.9em;
  }

  .navbar-button {
    padding: 8px 18px;
    font-size: 0.85em;
  }
}
</style>
  