<template>
  <div class="container">
    <div class="chemotherapy-container">
      <!-- Header Section -->
      <header class="header text-center">
        <h1 class="display-4 fade-in">{{ $t('chemotherapyTitle910') }}</h1>
        <p class="lead fade-in">{{ $t('chemotherapyDescription911') }}</p>
      </header>

      <!-- Chemotherapy Overview Section -->
      <section class="overview">
        <h2 class="section-title fade-in">{{ $t('chemotherapyWhatIsTitle912') }}</h2>
        <p class="fade-in">
          {{ $t('chemotherapyOverview913') }}
        </p>
        <div class="card-deck">
          <div class="card fade-in">
            <div class="card-body">
              <h5 class="card-title">{{ $t('chemotherapyHospital914') }}</h5>
              <p class="card-text">{{ $t('chemotherapyHospitalDescription915') }}</p>
            </div>
          </div>
          <div class="card fade-in">
            <div class="card-body">
              <h5 class="card-title">{{ $t('chemotherapySpecialists916') }}</h5>
              <p class="card-text">{{ $t('chemotherapySpecialistsDescription917') }}</p>
            </div>
          </div>
        </div>
        <p class="fade-in">
          {{ $t('chemotherapyServicesTitle918') }}
        </p>
        <ul class="fade-in">
          <li>{{ $t('chemotherapyService1') }}</li>
          <li>{{ $t('chemotherapyService2') }}</li>
          <li>{{ $t('chemotherapyService3') }}</li>
          <li>{{ $t('chemotherapyService4') }}</li>
        </ul>
      </section>

      <section class="special-services">
        <h2 class="section-title fade-in">{{ $t('chemotherapySpecialServicesTitle919') }}</h2>
        <div class="card-deck">
          <div class="card fade-in">
            <div class="card-body">
              <h5 class="card-title">{{ $t('chemotherapyNutritionDisorders920') }}</h5>
              <p class="card-text">{{ $t('chemotherapyNutritionDisordersDescription921') }}</p>
            </div>
          </div>
          <div class="card fade-in">
            <div class="card-body">
              <h5 class="card-title">{{ $t('chemotherapyPalliativeTreatment922') }}</h5>
              <p class="card-text">{{ $t('chemotherapyPalliativeTreatmentDescription923') }}</p>
            </div>
          </div>
        </div>
        <p class="fade-in">
          {{ $t('chemotherapyPalliativeCare924') }}
        </p>
      </section>

      <section class="specialized-care">
        <h2 class="section-title fade-in">{{ $t('chemotherapySpecializedCareTitle925') }}</h2>
        <div class="card-deck">
          <div class="card fade-in">
            <div class="card-body">
              <h5 class="card-title">{{ $t('chemotherapyOncogynaecology926') }}</h5>
              <p class="card-text">{{ $t('chemotherapyOncogynaecologyDescription927') }}</p>
            </div>
          </div>
          <div class="card fade-in">
            <div class="card-body">
              <h5 class="card-title">{{ $t('chemotherapyOncouroology928') }}</h5>
              <p class="card-text">{{ $t('chemotherapyOncouroologyDescription929') }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="chemo-radiotherapy">
        <h2 class="section-title fade-in">{{ $t('chemotherapyChemoRadiotherapyTitle930') }}</h2>
        <p class="fade-in">
          {{ $t('chemotherapyChemoRadiotherapyDescription931') }}
        </p>
      </section>

      <section class="image-section fade-in">
        <div class="image-container">
          <img :src="img2" alt="Kimyo Terapiya" class="responsive-img" />
        </div>
      </section>
    </div>
  </div>
</template>

  
  <script>
  import img2 from '@/assets/tera.jpg'
  export default {
    data(){
      return{
        img2
      }
    }
  }
  </script>
  
  <style scoped>
  /* Container */
  .chemotherapy-container {
    padding: 50px 15px;
    font-family: 'Roboto', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header */
  .header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  h1 {
    font-size: 3rem;
    color: #003366;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  p.lead {
    font-size: 1.2rem;
    color: #007bff;
    font-weight: 500;
  }
  
  /* Section Titles */
  .section-title {
    font-size: 2.5rem;
    color: #003366;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  /* Paragraphs */
  p {
    font-size: 1.15rem;
    color: #555;
    line-height: 1.8;
    text-align: center;
    margin-bottom: 20px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Fading Animation */
  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
    text-align: center;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Card Styles */
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .card {
    width: 280px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #003366;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
  }
  
  /* Image Container */
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .responsive-img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .responsive-img:hover {
    transform: scale(1.05);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  
    h1 {
      font-size: 2.2rem;
    }
  
    .card {
      width: 100%;
    }
  }
  </style>
  