<template>
  <div class="container">
    <div class="contact_bg">
      <p class="title">{{ $t("contactTitle950") }}</p>

      <div class="contact-form">
        <!-- Contact Info Section -->
        <div class="info-section">
          <div class="info-item">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 384 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon1"
            >
              <path
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
              ></path>
            </svg>
            <div>
              <p class="info-title">{{ $t("contactAddress951") }}</p>
              <p class="info-details">{{ $t("contactAddressDetails952") }}</p>
            </div>
          </div>

          <div class="info-item">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
            >
              <path
                d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
              ></path>
            </svg>
            <div>
              <p class="info-title">{{ $t("contactPhone953") }}</p>
              <p class="info-details">+998 97 860 18 88</p>
            </div>
          </div>

          <div class="info-item">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M22 8.98V18c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h10.1c-.06.32-.1.66-.1 1 0 1.48.65 2.79 1.67 3.71L12 11 4 6v2l8 5 5.3-3.32c.54.2 1.1.32 1.7.32 1.13 0 2.16-.39 3-1.02zM16 5c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z"
              ></path>
            </svg>
            <div>
              <p class="info-title">{{ $t("contactEmail955") }}</p>
              <p class="info-details">example.@gmail.com</p>
            </div>
          </div>
        </div>

        <!-- Google Map Section -->
        <div class="map-section">
          <div class="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3064.8850584859783!2d64.424716!3d39.80957!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f5008d1e6c5066f%3A0xd75e17232f56d5dd!2z0JHRg9GF0LDRgNGB0LrQuNC5INC-0LHQu9Cw0YHRgtC90L7QuSDQvtC90LrQvtC70L7Qs9C40YfQtdGB0LrQuNC5INC00LjRgdC_0LDQvdGB0LXRgA!5e0!3m2!1sru!2sus!4v1733988708981!5m2!1sru!2sus"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <!-- <iframe
              width="100%"
              height="100%"
              src="https://www.google.com/maps/place/Chorsu+Bazaar/@41.328984,69.2452344,14z/data=!4m6!3m5!1s0x38ae8b9cdd04953d:0xf8109d550917a88e!8m2!3d41.3267357!4d69.2350318!16s%2Fm%2F0vshb5f?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"
              frameborder="0"
              style="border: 0; border-radius: 8px"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              loading="lazy"
            ></iframe> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.contact-form {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  width: 100%;
  padding: 20px;
}

.title {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.info-section {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  gap: 20px;
  padding: 20px;
}

.info-item {
  text-align: center;
  gap: 15px;
  width: 100%;
}

.icon {
  font-size: 40px;
  color: #06829b;
}

.info-title {
  font-weight: bold;
  margin: 0;
  font-size: 24px;
}

.info-details {
  margin: 0;
  font-size: 16px;
}

.map-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* padding: 0 20px; */
  /* margin-top: 20px; */
}

.map-container {
  width: 100%;
  height: 300px;
  position: relative;
}

@media (max-width: 767px) {
  .contact-form {
    flex-direction: column;
    align-items: center;
  }

  .info-section {
    width: 100%;
    padding: 10px;
  }

  .map-container {
    height: 250px;
  }

  .title {
    font-size: 30px;
  }

  .info-title {
    font-size: 20px;
  }

  .info-details {
    font-size: 14px;
  }

  .icon {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contact-form {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .info-section {
    width: 30%;
  }

  .map-container {
    height: 400px;
  }

  .title {
    font-size: 36px;
  }

  .info-title {
    font-size: 22px;
  }

  .info-details {
    font-size: 15px;
  }

  .icon {
    font-size: 35px;
  }
}

@media (min-width: 1025px) {
  .contact-form {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .info-section {
    width: 40%;
  }

  .map-container {
    height: 450px;
  }
}
</style>
