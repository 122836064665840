<template>
  <div class="container">
    <p class="Title">{{ $t("sectionsTitle") }}</p>
    <div class="card-list">
      <div 
        class="card" 
        v-for="(item, index) in items" 
        :key="index" 
        @click="item.action"
      >
        <div class="info"> 
          <a :href="item.link">
          <div class="card-content">
              <div class="card-text">
                <p>{{ $t(item.text) }}</p>
              </div>
            
          </div>
          <div class="card-action">
            <button class="action-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" class="arrow-icon">
                <path d="M9 18l6-6-6-6" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'surgeryOne', 
          link: '/surgeryI',
        },
        {
          text: 'surgeryTwo', 
          link: '/surgeryII',
        },
        {
          text: 'surgeryThree', 
          link: '/surgeryIII',
        },
        {
          text: 'laboratoryPathologyy', 
          link: '/laboratoryPathology',
        },
        {
          text: 'radiologyDiagnosis', 
          link: '/radiologyDiagnosis',
        },
        {
          text: 'palliativeCaree', 
          link: '/polyclinic',
        },
      ]
    };
  },
  computed: {
    translatedItems() {
      return this.items.map(item => {
        return {
          ...item,
          text: this.$t(item.text) 
        };
      });
    }
  }
};
</script>


  
<style lang="scss" scoped>
.Title {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin: 50px 0;
  color: #333;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  padding: 10px;
  justify-items: center;
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  align-items: center;  /* Vertically center the card content */
  justify-content: center;  /* Horizontally center the content */
  padding: 15px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  height: 100px;
  position: relative;  /* Needed for hover effect */
}

.card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.card:hover {
  .arrow-icon {
    stroke: #fff;
    border: 1px solid #fff;
  }

  .card-text p {
    color: #fff;
  }
}

.card-content {
  display: flex;
  align-items: center;  /* Vertically center content */
  justify-content: center;  /* Horizontally center content */
  gap: 15px;
  flex-grow: 1;
}

.card-text p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  line-height: 1.5;
  word-wrap: break-word;
  text-align: center;  /* Ensure text is centered inside the card */
}

.card-action {
  flex-shrink: 0;
  display: flex;
  justify-content: center;  /* Horizontally center action button */
  align-items: center;  /* Vertically center action button */
}

.info a{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  gap: 10px;
  list-style: none; 
  text-decoration: none;
  text-align: end;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.action-button:hover {
  transform: translateX(5px);
}

.arrow-icon {
  width: 20px;
  height: 20px;
  stroke: #9E9E9E;
  border: 1px solid #9E9E9E;
  border-radius: 50%;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #06829B;
  transition: transform 0.5s ease-out;
  transform: scaleY(0);  
  transform-origin: bottom; 
  z-index: -1;
}

.card:hover::before {
  transform: scaleY(1); 
}

@media (max-width: 1024px) {
  .Title {
    font-size: 36px; /* Slightly smaller title on tablets */
  }

  .card-list {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on tablets */
  }

  .card {
    height: 80px; /* Adjust card height on tablets */
    padding: 12px;
  }

  .card-text p {
    font-size: 13px; /* Adjust font size on tablets */
  }

  .arrow-icon {
    width: 18px; /* Smaller arrow icon on tablets */
    height: 18px;
  }
}

/* For Smaller Tablets and Large Phones */
@media (max-width: 768px) {
  .Title {
    font-size: 32px; /* Title size smaller on smaller tablets */
    margin-bottom: 20px;
  }

  .card-list {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on smaller tablets */
  }

  .card {
    height: 75px; /* Adjust card height */
    padding: 10px;
  }

  .card-text p {
    font-size: 12px; /* Adjust font size */
  }

  .arrow-icon {
    width: 16px;
    height: 16px;
  }

  .action-button {
    padding: 4px;
  }
}

/* For Phones */
@media (max-width: 480px) {
  .Title {
    font-size: 28px; /* Further smaller title size for mobile */
    margin-bottom: 15px;
  }

  .card-list {
    grid-template-columns: 1fr; /* 1 card per row on mobile */
  }

  .card {
    height: 65px; /* Adjust card height on mobile */
    padding: 8px;
  }

  .card-text p {
    font-size: 11px; 
    padding-top: 10px;
  }

  .arrow-icon {
    width: 14px;
    height: 14px;
  }

  .action-button {
    padding: 3px;
    padding-top: 10px;
  }
}

</style>
