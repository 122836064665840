<template>
  <div class="container">
   <div class="radiology-section">
       <!-- Header Section -->
       <header class="header text-center mb-5">
         <p class="mainh1">{{ $t('radiologyTitle810') }}</p>
         <p class="lead">{{ $t('radiologyDescription811') }}</p>
       </header>
   
       <!-- Radiatsiya Terapiyasi Section -->
       <section class="radiation-therapy">
         <p class="mainh2 section-title fade-in">{{ $t('radiationTherapyTitle812') }}</p>
         <p class="fade-in">{{ $t('radiationTherapyDescription813') }}</p>
       </section>
   
       <!-- Pathologies Section -->
       <section class="pathologies">
         <p class="mainh2 section-title fade-in">{{ $t('radiationPathologiesTitle814') }}</p>
         <ul class="pathology-list fade-in">
           <li>{{ $t('pathology1') }}</li>
           <li>{{ $t('pathology2') }}</li>
           <li>{{ $t('pathology3') }}</li>
           <li>{{ $t('pathology4') }}</li>
           <li>{{ $t('pathology5') }}</li>
           <li>{{ $t('pathology6') }}</li>
           <li>{{ $t('pathology7') }}</li>
           <li>{{ $t('pathology8') }}</li>
           <li>{{ $t('pathology9') }}</li>
           <li>{{ $t('pathology10') }}</li>
           <li>{{ $t('pathology11') }}</li>
           <li>{{ $t('pathology12') }}</li>
           <li>{{ $t('pathology13') }}</li>
           <li>{{ $t('pathology14') }}</li>
           <li>{{ $t('pathology15') }}</li>
         </ul>
       </section>
   
       <!-- Modern Equipment Section -->
       <section class="equipment">
         <p class="mainh2 section-title fade-in">{{ $t('modernEquipmentTitle815') }}</p>
         <p class="fade-in">{{ $t('modernEquipmentDescription816') }}</p>
       </section>
   
       <!-- Radiology Methods Section -->
       <section class="radiology-methods">
         <p class="mainh2 section-title fade-in">{{ $t('radiologyMethodsTitle817') }}</p>
         <div class="methods-list fade-in">
           <div class="method-card">
             <p class="mainh3">{{ $t('radiologyMethod1') }}</p>
             <p>{{ $t('radiologyMethod1Description') }}</p>
           </div>
           <div class="method-card">
             <p class="mainh3">{{ $t('radiologyMethod2') }}</p>
             <p>{{ $t('radiologyMethod2Description') }}</p>
           </div>
           <div class="method-card">
             <p class="mainh3">{{ $t('radiologyMethod3') }}</p>
             <p>{{ $t('radiologyMethod3Description') }}</p>
           </div>
           <div class="method-card">
             <p class="mainh3">{{ $t('radiologyMethod4') }}</p>
             <p>{{ $t('radiologyMethod4Description') }}</p>
           </div>
           <div class="method-card">
             <p class="mainh3">{{ $t('radiologyMethod5') }}</p>
             <p>{{ $t('radiologyMethod5Description') }}</p>
           </div>
         </div>
       </section>
   
       <!-- Hospital Beds Section -->
       <section class="hospital-beds">
         <p class="mainh2 section-title fade-in">{{ $t('hospitalBedsTitle818') }}</p>
         <p class="fade-in">{{ $t('hospitalBedsDescription819') }}</p>
       </section>
     </div>
  </div>
</template>

  
  <script>
  export default {
    name: "RadiologySection",
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .radiology-section {
    padding: 50px 0;
  }
  
  .mainh1{
    font-size: 2.5rem;
    color: #003366;
  }
  .mainh2{
  font-size: 2rem;
  color: #003366;
  }
  
  .mainh3 {
    color: #333;
    font-size: 1.8rem;
  }
  
  .section-title {
    font-size: 1.9rem;
    margin-bottom: 20px;
    text-align: center;
    color: #005b96;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  ul,
  .methods-list {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    padding-left: 20px;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
  }
  
  li:hover {
    transform: translateX(10px);
  }
  
  .method-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .method-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .row {
    margin-bottom: 30px;
  }
  
  @media (min-width: 768px) {
    .methods-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    .method-card {
      width: 30%;
      margin-bottom: 30px;
    }
  
    .mainh1 {
      font-size: 2.5rem;
    }
  
    .mainh2 {
      font-size: 2rem;
    }
  
    .mainh3 {
      font-size: 1.8rem;
    }
  
    .section-title {
      font-size: 1.9rem;
    }
  }
  
  /* For tablets (screen widths between 600px and 768px) */
  @media (max-width: 768px) {
    .method-card {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .pathology-list {
      font-size: 1rem;
    }
  
    .mainh1 {
      font-size: 2rem;
    }
  
    .mainh2 {
      font-size: 1.8rem;
    }
  
    .mainh3 {
      font-size: 1.6rem;
    }
  
    .section-title {
      font-size: 1.7rem;
    }
  }
  
  /* For small screens (phones with screen widths under 600px) */
  @media (max-width: 600px) {
    .method-card {
      width: 100%;
      margin-bottom: 20px;
      padding: 15px;
    }
  
    .pathology-list {
      font-size: 1rem;
    }
  
    .mainh1 {
      font-size: 1.6rem;
      text-align: center;
    }
  
    .mainh2 {
      font-size: 1.5rem;
      text-align: center;
    }
  
    .mainh3 {
      font-size: 1.4rem;
    }
  
    .section-title {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
  
    .radiology-section {
      padding: 30px 0;
    }
  
    /* Reduce padding and margins for better fit */
    .container {
      padding: 0 10px;
    }
  
    ul, .methods-list {
      padding-left: 0;
      padding-right: 0;
    }
  
    li {
      padding-left: 10px;
    }
  }
  </style>
  