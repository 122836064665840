<template>
  <div class="container">
    <div class="doctor-profile">
      <div class="image-section">
        <img
          class="doctor-image"
          :src="UmidImage"
          alt="Doctor"
        />
      </div>

      <div class="info-section">
        <!-- Doctor's Name -->
        <p class="mainh1">{{ $t('doctorName510') }}</p>

        <!-- Education Section -->
        <div class="info-block">
          <p class="mainh2">{{ $t('education511') }}</p>
          <ul>
            <li>{{ $t('educationDetails512') }}</li>
            <li>{{ $t('educationDetails513') }}</li>
            <li>{{ $t('educationDetails514') }}</li>
            <li>{{ $t('educationDetails515') }}</li>
          </ul>
        </div>

        <!-- Work Experience Section -->
        <div class="info-block">
          <p class="mainh2">{{ $t('workExperience516') }}</p>
          <ul>
            <li>{{ $t('workExperienceDetails517') }}</li>
            <li>{{ $t('workExperienceDetails518') }}</li>
            <li>{{ $t('workExperienceDetails519') }}</li>
            <li>{{ $t('workExperienceDetails520') }}</li>
            <li>{{ $t('workExperienceDetails521') }}</li>
          </ul>
        </div>

        <!-- International Experience Section -->
        <div class="info-block">
          <p class="mainh2">{{ $t('internationalExperience522') }}</p>
          <ul>
            <li>{{ $t('internationalExperienceDetails523') }}</li>
            <li>{{ $t('internationalExperienceDetails524') }}</li>
            <li>{{ $t('internationalExperienceDetails525') }}</li>
            <li>{{ $t('internationalExperienceDetails526') }}</li>
          </ul>
        </div>

        <!-- Recognitions and Activities Section -->
        <div class="info-block">
          <p class="mainh2">{{ $t('recognitions527') }}</p>
          <ul>
            <li>{{ $t('recognitionsDetails528') }}</li>
            <li>{{ $t('recognitionsDetails529') }}</li>
            <li>{{ $t('recognitionsDetails530') }}</li>
            <li>{{ $t('recognitionsDetails531') }}</li>
            <li>{{ $t('recognitionsDetails532') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UmidImage from '../../assets/doctor/mamedov_umid_sunnatovich.jpg';
export default {
  data() {
    return {
      UmidImage
    };
  }
};
</script>

<style scoped>
.doctor-profile {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doctor-image {
  border-radius: 20px;
  border: 5px solid #06829b;
  height: 600px;
  margin-bottom: 200px;
}

.info-section {
  flex: 2;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mainh1 {
  font-size: 2.5rem;
  color: #003366;
}

.mainh2 {
  font-size: 2rem;
  color: #003366;
}

.mainh3 {
  color: #333;
  font-size: 1.8rem;
}

.info-block {
  margin-top: 20px;
}

.info-block h2 {
  font-size: 22px;
  color: #003366;
  margin-bottom: 10px;
}

.info-block ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #333;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .doctor-profile {
    flex-direction: column;
    align-items: center;
  }

  .doctor-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .info-section {
    width: 100%;
  }

  .mainh1 {
    font-size: 2rem;
  }

  .mainh2 {
    font-size: 1.6rem;
  }

  .info-block h2 {
    font-size: 18px;
  }

  .info-block ul {
    margin-left: 15px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .mainh1 {
    font-size: 1.8rem;
  }

  .doctor-image{
    width: 400px;
    height: 500px;
  }

  .mainh2 {
    font-size: 1.4rem;
  }

  .info-block h2 {
    font-size: 16px;
  }

  .info-block ul {
    font-size: 0.9rem;
  }
}
</style>
