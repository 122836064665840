<template>
  <div class="container">
    <p class="mainh1">{{ $t('I-Jarrohlik') }}</p>
    <div class="main">
      <div class="left">
        <img :src="Jarrohlik" alt="jarroh" />
      </div>
      <div class="right">
        <p class="section-title">{{ $t('sectionTitle') }}</p>
        <p>{{ $t('intro') }}</p>
        <p>
          {{ $t('consultation') }}
          <br />
          <strong>{{ $t('doctor1') }} +99894 035 44 47</strong>
          <br />
          <strong>{{ $t('doctor2') }} +99891 408-57 79</strong>
        </p>
        <p>
          {{ $t('branchPhone') }} +99865 228 58 50.
        </p>
        <p v-if="isExpanded">
          {{ $t('advice') }}
        </p>
        <button @click="toggleContent" class="read-more-btn">{{ isExpanded ? $t('showLess') : $t('readMore') }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Jarrohlik from '@/assets/Jarrohlik.jpg';

const isExpanded = ref(false);

const toggleContent = () => {
  isExpanded.value = !isExpanded.value;
};
</script>

<style scoped>

.mainh1 {
  text-align: center;
  font-size: 2.7rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.main {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
  height: 90vh;
}

.section-title{
  font-size: 22px;
  font-weight: 600;
}

.left img {
  width: 600px;
  height: 400px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.right {
  flex: 1;
  max-width: 600px;
  line-height: 1.6;
  font-size: 1.1rem;
  color: #555;
}

.right h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #2d2d2d;
}

.right p {
  margin-bottom: 20px;
}

a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.read-more-btn {
  background-color: #06829b;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

.read-more-btn:hover {
  background-color: #155f8a;
}


@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
    gap: 20px;
  }
  .left img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  .right {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 100%;
  }
  .mainh1 {
    font-size: 2rem;
  }
  .section-title {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .main {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .left img {
    width: 90%;
    max-width: 400px;
    margin-bottom: 20px;
  }
  .right {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 15px;
  }
  .mainh1 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  .section-title {
    font-size: 1.3rem;
  }
  .read-more-btn {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .main {
    flex-direction: column;
    gap: 15px;
    padding: 0 10px;
  }
  .left img {
    width: 100%;
    max-width: 350px;
    height: auto;
    margin-bottom: 15px;
  }
  .right {
    font-size: 0.95rem;
    padding: 0 10px;
  }
  .mainh1 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .section-title {
    font-size: 1.2rem;
  }
  .read-more-btn {
    font-size: 0.85rem;
    padding: 6px 10px;
  }
}
</style>
