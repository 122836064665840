<template>
  <div style="height: 200px;">
    <div class="appnavbar">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-container">
            <div class="navbar-logo">
              <div>
                <router-link to="/">
                  <img
                    class="logo rounded-circle"
                    :src="Logo"
                    alt="Markaz Logosi"
                  />
                </router-link>
              </div>
              <div class="navbar-title">
                <h6>
                  {{ $t('title') }}
                </h6>
              </div>
            </div>

            <ul class="navbar-links">
              <li>
                <div class="select">
                  <label for="language-select" class="sr-only"
                    >Tilni tanlang</label
                  >
                  <select
                    class="selects"
                    v-model="selectedLanguage"
                    @change="changeLanguage"
                    id="language-select"
                  >
                    <option class="options" value="uz">O'zbek</option>
                    <option class="options" value="en">English</option>
                    <option class="options" value="ru">Русский</option>
                  </select>
                </div>
              </li>
            </ul>

            <div
              class="btn navbar-toggle"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              @click="toggleMenu"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>

      <div>
        <div class="navbar_dropdown">
          <div class="container">
            <AppDropdown></AppDropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- Offcanvas Menu -->
    <div
      class="offcanvas offcanvas-end menu"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <ul class="offcanvas-title" id="offcanvasRightLabel">
          <li>
            <div class="select">
              <label for="offcanvas-language-select" class="sr-only"
                >Tilni tanlang</label
              >
              <select
                class="selects"
                v-model="selectedLanguage"
                @change="changeLanguage"
                id="offcanvas-language-select"
              >
                <option class="options" value="uz">O'zbek</option>
                <option class="options" value="en">English</option>
                <option class="options" value="ru">Русский</option>
              </select>
            </div>
          </li>
       
        </ul>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Yopish"
        ></button>
      </div>
      <div class="offcanvas-body">
        <!-- Offcanvas content -->

          <AppDropdown></AppDropdown>
     
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import Logo from "@/assets/hospital_logg.png";
import AppDropdown from "./AppDropdown.vue";
import { useStore } from "vuex"; 
import { useI18n } from "vue-i18n"; 
const store = useStore(); 
const { t, locale } = useI18n();

const isMenuOpen = ref(false);
const selectedLanguage = computed({
  get() {
    return store.getters.getSelectedLanguage; 
  },
  set(newLang) {
    store.dispatch("changeLanguage", newLang); 
    locale.value = newLang; 
    window.location.reload();
  },
});

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

// Show dropdown menu
// const showMenu = () => {
//   const dropdownContent = document.querySelector(".dropdown");
//   const chevron = document.getElementById("chevron");

//   dropdownContent.classList.toggle("active");
//   chevron.classList.toggle("rotate");
// };

// Close dropdown if clicked outside
// const handleClickOutside = (event) => {
//   const dropdownContent = document.querySelector(".dropdown");
//   const chevron = document.getElementById("chevron");

//   if (!event.target.matches(".dropdown-button")) {
//     dropdownContent.classList.remove("active");
//     chevron.classList.remove("rotate");
//   }
// };

// onMounted(() => {
//   window.addEventListener("click", handleClickOutside);
// });

// onBeforeUnmount(() => {
//   window.removeEventListener("click", handleClickOutside);
// });
</script>

<style scoped>
.appnavbar {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  position: fixed;
  
  
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #06829b;
  color: white;
  padding: 15px 0;
 
}

.linkss {
  display: flex;
  gap: 16px;
}

.navbar-title h6 {
  margin: 0;
  width: 200px;
  font-size: 14px;
  text-align: left;
  line-height: 1.4;
  color: #ffffff;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar-logo img {
  width: 70px;
}

.navbar-links li {
  list-style: none;
}

.select {
  border-radius: 5px;
}

.selects {
  border: none;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  background: #ffffff;
  color: #333;
  border-radius: 5px;
}

#language-select,
#offcanvas-language-select {
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 6px;
}

.navbar-toggle span {
  background-color: white;
  height: 3px;
  width: 25px;
  border-radius: 2px;
}

.menu {
  width: 60%;
  background: #06829b;
}

.navbar_dropdown {
  background: #07768d;
}

.chevron-icon {
  width: 1rem;
  height: 1rem;
  transform: rotate(0);
  transition: transform 0.3s ease;
}
.chevron-icon.rotate {
  transform: rotate(-180deg);
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar_dropdown{
    display: none;
  }

  .navbar-toggle {
    display: flex;
  }
}

.chevron-icon {
  width: 1rem;
  height: 1rem;
  transform: rotate(0);
  transition: transform 0.3s ease;
}
.chevron-icon.rotate {
  transform: rotate(-180deg);
}
</style>
