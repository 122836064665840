<template>
  <div class="footer">
    <div class="container">
      <div class="footer-logo">
        <div>
          <img class="Img rounded-circle" :src="Logo" alt="Hospital Logo" />
        </div>
        <div>
          <h4>{{ $t("hospital_name") }}</h4>
        </div>

        <div class="footer-social-media">
          <ul class="social-media-list">
            <li
              v-for="(link, index) in socialLinks"
              :key="index"
              class="social-media-item"
            >
              <a :href="link.url" target="_blank" rel="noopener noreferrer">
                <span v-html="link.icon"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="footer-container">
        <div class="footer-operational">
          <h5>{{ $t("contactAddress951") }}:</h5>
          <p>{{ $t('adresss_location') }}</p>
        </div>

        <div class="footer-operational second">
          <h5>{{ $t("operational_hours") }}:</h5>
          <p>08:00 {{ $t("from") }} 14:00 {{ $t("to") }}</p>
        </div>

        <div class="footer-operational">
          <h5>{{ $t("contact") }}:</h5>
          <p>+998 (65) 228-58-50</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      Logo: require("@/assets/hospital_logg.png"),

      socialLinks: [
        {
          url: "https://facebook.com",
          icon: "<i class='fab fa-facebook'></i>",
        },
        { url: "https://twitter.com", icon: "<i class='fab fa-twitter'></i>" },
        {
          url: "https://instagram.com",
          icon: "<i class='fab fa-instagram'></i>",
        },
      ],
    };
  },
};
</script>

<style scoped>
.Img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.footer {
  background-color: #06829b;
  color: #d4eaf2;
  padding: 40px 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
}

.footer-logo {
  text-align: center;
  margin-bottom: 20px;
  flex: 1;
}

.footer-logo h4 {
  font-size: 18px;
  font-weight: bold;
  color: #d4eaf2;
}

.footer-social-media ul {
  display: flex;
  justify-content: center;
  gap: 15px;
  list-style: none;
  padding: 0;
}

.social-media-item a {
  color: #d4eaf2;
  font-size: 20px;
  transition: color 0.3s ease-in-out;
}

.social-media-item a:hover {
  color: #1ab3c8;
}

.footer-operational {
  flex: 1;
  text-align: center;
}

.footer-operational h5 {
  font-size: 16px;
  font-weight: 600;
  color: #1ab3c8;
}

.footer-operational p {
  font-size: 14px;
  color: #d4eaf2;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 20px;
    align-items: center;  
  }

  .Img {
    width: 60px;
    height: 60px;
  }

  .footer-logo h4 {
    font-size: 16px;
  }

  .footer-operational {
    text-align: center;
    flex: none;  
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
