import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import store from './store';

import i18n from './I18n'
import 'swiper/css';  // Import core Swiper styles
import 'swiper/css/navigation';  // Import navigation styles
import 'swiper/css/pagination';  // Import pagination styles
import '@/assets/scss/global.scss';
import '@/assets/scss/variables.scss';
// Import Bootstrap CSS and JS (includes Popper.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Import Font Awesome (only the newer version is needed)
import '@fortawesome/fontawesome-free/css/all.css';
// Axios base URL
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .component('Swiper', Swiper)  // Register Swiper globally
  .component('SwiperSlide', SwiperSlide)  // Register SwiperSlide globally
  .mount('#app');
