<template>
  <div class="container">
    <div class="all-In">
      <p class="title">{{ $t("header1000") }}</p>
      <div class="main">
        <div class="rating" v-for="rating in ratings" :key="rating.platform">
          <span class="platform-icon">{{ $t(rating.platform) }}</span>
          <span class="review-count">{{ rating.currentCount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, onMounted } from "vue";

// Define ratings as a reactive ref
const ratings = ref([
  { platform: "lang1", reviewCount: 6000, currentCount: 0 },
  { platform: "lang2", reviewCount: 1000, currentCount: 0 },
  { platform: "lang3", reviewCount: 2000, currentCount: 0 },
]);

// Function to start counting
let interval = null;
const startCounting = () => {
  interval = setInterval(() => {
    let completed = true;

    ratings.value.forEach((rating) => {
      if (rating.currentCount < rating.reviewCount) {
        rating.currentCount += 5;
        if (rating.currentCount > rating.reviewCount) {
          rating.currentCount = rating.reviewCount;
        }
        completed = false;
      }
    });

    if (completed) {
      clearInterval(interval);
    }
  }, 15);
};

onMounted(() => {
  startCounting();
});
</script>
  
  <style scoped lang="scss">
.all-In {
  padding: 30px;
  background-color: #f4f6f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
}

.main {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.title {
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
  color: #2c3e50;
}

.rating {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  width: 350px;
}

.platform-icon {
  font-weight: 700;
  color: #06829b;
  margin-bottom: 10px;
}

.review-count {
  font-weight: 600;
  font-size: 40px;
  color: black;
}

@media (max-width: 1024px) {
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .rating {
    width: 680px;
    padding: 15px;
  }

  .platform-icon {
    font-size: 1.2rem;
  }

  .review-count {
    font-size: 30px;
  }
}
</style>
  