<template>
  <div class="container">
    <!-- Header Section -->
    <header class="header text-center">
      <p class="mainh1">{{ $t('poliklinikaTitle850') }}</p>
      <p class="lead">{{ $t('poliklinikaDescription851') }}</p>
    </header>

    <!-- Overview Section -->
    <section class="overview fade-in">
      <div class="section-title">
        <p class="mainh2">{{ $t('poliklinikaOverviewTitle852') }}</p>
      </div>
      <div class="cards-container">
        <div class="card">
          <p class="mainh3">{{ $t('specialistsRoomTitle853') }}</p>
          <p class="card-text">{{ $t('specialistsRoomDescription854') }}</p>
        </div>
        <div class="card">
          <p class="mainh3">{{ $t('medicalAssistanceTitle855') }}</p>
          <p class="card-text">{{ $t('medicalAssistanceDescription856') }}</p>
        </div>
        <div class="card">
          <p class="mainh3">{{ $t('specialServicesTitle857') }}</p>
          <p class="card-text">{{ $t('specialServicesDescription858') }}</p>
        </div>
      </div>
    </section>

    <!-- Image Section -->
    <section class="image-section fade-in">
      <div class="image-container">
        <img :src="img2" alt="Poliklinika" class="responsive-img" />
      </div>
    </section>

    <!-- Medical Services Section -->
    <section class="services diggo fade-in">
      <div class="section-title">
        <p class="mainh2">{{ $t('oncologyServicesTitle859') }}</p>
      </div>
      <ul class="services-list">
        <li>{{ $t('oncologyService1') }}</li>
        <li>{{ $t('oncologyService2') }}</li>
        <li>{{ $t('oncologyService3') }}</li>
      </ul>
    </section>

    <!-- Special Services Section -->
    <section class="special-services fade-in">
      <div class="section-title">
        <p class="mainh2">{{ $t('specialTreatmentTitle860') }}</p>
      </div>
      <p class="service-description">{{ $t('specialTreatmentDescription861') }}</p>
      <p class="service-description">{{ $t('anorexiaTreatmentDescription862') }}</p>
      <p class="service-description">{{ $t('chemotherapyPostSurgeryDescription863') }}</p>
    </section>

    <!-- Specialized Care Section -->
    <section class="specialized-care fade-in">
      <div class="section-title">
        <p class="mainh2">{{ $t('specializedCareTitle864') }}</p>
      </div>
      <p class="service-description">{{ $t('specializedCareDescription865') }}</p>
      <ul class="services-list">
        <li>{{ $t('specializedCare1') }}</li>
        <li>{{ $t('specializedCare2') }}</li>
        <li>{{ $t('specializedCare3') }}</li>
        <li>{{ $t('specializedCare4') }}</li>
        <li>{{ $t('specializedCare5') }}</li>
      </ul>
    </section>

    <!-- Chemotherapy and Radiotherapy Section -->
    <section class="chemo-radiotherapy fade-in">
      <div class="section-title">
        <p class="mainh2">{{ $t('chemoRadiotherapyTitle866') }}</p>
      </div>
      <p class="service-description">{{ $t('chemoRadiotherapyDescription867') }}</p>
    </section>

    <!-- Registration Section -->
    <section class="registration fade-in">
      <div class="section-title">
        <p class="mainh2">{{ $t('registrationTitle868') }}</p>
      </div>
      <p class="registration-text">{{ $t('registrationText869') }}</p>
      <p class="registration-text">{{ $t('registrationCardDescription870') }}</p>
    </section>
  </div>
</template>

  
  <script>
  import img2 from '@/assets/Poliklinika.jpg'
  export default {
    data(){
        return{
            img2
        }
    }
    
  };
  </script>
  
  <style scoped>
  
  
  .header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .mainh1{
    font-size: 2.5rem;
    color: #003366;
  }
  .mainh2{
  font-size: 2rem;
  color: #003366;
  }
  
  .mainh3 {
    color: #333;
    font-size: 1.8rem;
  }

  .diggo{
    padding-top: 30px;
  }
  
  p.lead {
    font-size: 1.25rem;
    font-weight: 300;
    color: #007bff;
    letter-spacing: 1px;
  }
  
  /* Section Title */
  .section-title {
    font-size: 2.5rem;
    color: #003366;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: transform 0.3s ease;
  }
  
  /* Text Style for Description */
  .service-description {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
    transition: color 0.3s ease;
  }
 
  
  /* Cards Layout */
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 40px;
  }
  
  .card {
    background-color: #f8f9fa;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    margin: 20px 0;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card h3 {
    font-size: 1.75rem;
    color: #003366;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .card-text {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.5;
    text-align: justify;
  }
  
  /* Fading Animation */
  .fade-in {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Image Section */
  .image-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .responsive-img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .services-list {
    list-style-type: none;
    padding: 0;
    font-size: 1.2rem;
    color: #555;
  }
  
  .services-list li {
    margin-bottom: 12px;
    line-height: 1.6;
  }
  
  /* Button */
  .contact-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 15px 25px;
    font-size: 1.5rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-btn:hover {
    background-color: #0056b3;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    }
  
    .cards-container {
      grid-template-columns: 1fr;
    }
  
    h1 {
      font-size: 2.5rem;
    }
  
    .card {
      padding: 20px;
    }
  
    .service-description {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 480px) {
    .mainh1 {
      font-size: 2rem;
    }
  
    .mainh2 {
      font-size: 1.6rem;
    }
  
    .mainh3 {
      font-size: 1.5rem;
    }
  
    .service-description {
      font-size: 1rem;
    }
  
    .cards-container {
      padding: 0 10px;
    }
  }
  
  </style>
  