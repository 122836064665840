<template>
  <nav>
    <div class="navbar">
      <div class="nav-links">
        <ul class="links">
          <!-- About Menu -->
          <li
            @click="toggleMenu('aboutMenu')"
            :class="{ open: activeMenu === 'aboutMenu' }"
          >
            <a href="#" class="menu-title">{{ $t("about") }}</a>
            <i class="fas fa-chevron-down arrow"></i>
            <ul class="sub-menu">
              <router-link class="rout" to="/aboutus">
                <li>{{ $t("about") }}</li>
              </router-link>
              <router-link class="rout" to="/management">
                <li>{{ $t("management") }}</li>
              </router-link>
              <router-link class="rout" to="/Information">
                <li>{{ $t("branchHistory") }}</li>
              </router-link>
              <router-link class="rout" to="/Information">
                <li>{{ $t("filial_struktura") }}</li>
              </router-link>
              <router-link class="rout" to="/Information">
                <li>{{ $t("xalqaro_aloqalar") }}</li>
              </router-link>
              <router-link class="rout" to="/Information">
                <li>{{ $t("standart_diagnostika") }}</li>
              </router-link>
            </ul>
          </li>
          <!-- Patient Menu -->
          <li
            @click="toggleMenu('patientMenu')"
            :class="{ open: activeMenu === 'patientMenu' }"
          >
            <a href="#" class="menu-title">{{ $t("bemorlar_uchun") }}</a>
            <i class="fas fa-chevron-down arrow"></i>
            <ul class="sub-menu">
              <router-link class="rout" to="/services">
                <li>{{ $t("xizmatlar") }}</li>
              </router-link>
            </ul>
          </li>
          <!-- Departments Menu -->
          <li
            @click="toggleMenu('departmentsMenu')"
            :class="{ open: activeMenu === 'departmentsMenu' }"
          >
            <a href="#" class="menu-title">{{ $t("sectionsTitle") }}</a>
            <i class="fas fa-chevron-down arrow"></i>
            <ul class="sub-menu">
              <router-link class="rout" to="/surgeryI">
                <li>{{ $t("surgeryOne") }}</li>
              </router-link>
              <router-link class="rout" to="/surgeryII">
                <li>{{ $t("surgeryTwo") }}</li>
              </router-link>
              <router-link class="rout" to="/surgeryIII">
                <li>{{ $t("surgeryThree") }}</li>
              </router-link>
              <router-link class="rout" to="/SurgeryVI">
                <li>{{ $t("surgeryFour") }}</li>
              </router-link>
              <router-link class="rout" to="/laboratoryPathology">
                <li>{{ $t("laboratoryPathologyy") }}</li>
              </router-link>
              <router-link class="rout" to="/radiologyDiagnosis">
                <li>{{ $t("radiologyDiagnosis") }}</li>
              </router-link>
              <router-link class="rout" to="/palliativeCaree">
                <li>{{ $t("palliativeCaree") }}</li>
              </router-link>
              <router-link class="rout" to="/radioterapiya">
                <li>{{ $t("radioterapiya") }}</li>
              </router-link>
              <router-link class="rout" to="/resuscitation">
                <li>{{ $t("resuscitation") }}</li>
              </router-link>
              <router-link class="rout" to="/ximiyaterapiya">
                <li>{{ $t("Ximiyaterapiya") }}</li>
              </router-link>
              <router-link class="rout" to="/polyclinic">
                <li>{{ $t("polyclinic") }}</li>
              </router-link>
            </ul>
          </li>
         
          <li>
            <a href="/doctors">{{ $t("doctors") }}</a>
          </li>
          <li>
            <a href="/news">{{ $t("news") }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  data() {
    return {
      activeMenu: null,
    };
  },
  methods: {
    toggleMenu(menuName) {
      this.activeMenu = this.activeMenu === menuName ? null : menuName;
    },
    closeMenu(event) {
      if (!event.target.closest(".links")) {
        this.activeMenu = null;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.closeMenu.bind(this));
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  padding: 10px 20px;
}

.links {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.links li {
  position: relative;
  padding: 0 14px;
  cursor: pointer;
}

.links li a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
}

.links li .arrow {
  margin-left: 5px;
  font-size: 13px;
  color: #fff;
  transition: transform 0.3s ease;
}

.links li.open .arrow {
  transform: rotate(180deg);
}

.sub-menu {
  display: none;
  position: absolute;
  top: 33px;
  left: 0;
  background-color: #3e8da8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  list-style: none;
  padding: 10px 0;
  z-index: 1000;
}

.links li.open .sub-menu {
  display: block;
}

.sub-menu .rout{
  padding: 5px 20px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sub-menu .rout:hover,
.sub-menu .rout:focus {
  background: #07768d;
}

.sub-menu .rout:last-child {
  border-bottom: none;
}

.sub-menu a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}

@media (max-width: 768px) {
  .links {
    display: block;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .links li {
    position: relative;
    padding: 4px 14px;
    cursor: pointer;
  }

  .links li a{
    font-size: 17px;
  }
}
</style>
