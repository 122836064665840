<template>
  <div>
    <router-view name="header"></router-view>
    <!-- Header View -->

    <router-view></router-view>
    <!-- Default View -->

    <router-view name="footer"></router-view>
    <!-- Footer View -->
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style scoped>
/* Add any styles here */
</style>
